import { ApiResponseType, API_HEADERS } from "../types";
import { getCookie, getSessionStorage, showToast } from "../utils";
import { instance } from "../utils/axios";
import endpoints from "../utils/endpoints";

//use for post method
export const makePostCall = ({
  url,
  apiPayload,
  isToast = false,
  baseUrl = endpoints.API_BASE_URL,
  content_type = "application/json",
  xTimezone,
}: {
  url: string;
  apiPayload?: any;
  isToast?: boolean;
  baseUrl?: string;
  content_type?: string;
  xTimezone?: string;
  amount?: any
}) => {
  return new Promise(async (resolve: (response: any) => void, reject) => {
    try {
      instance.defaults.baseURL = baseUrl;
      instance.defaults.headers.post["Content-Type"] = content_type;
      if (xTimezone) {
        instance.defaults.headers["x-timezone"] = xTimezone;
      }

      // switch (accessTokenType) {
      // 	case "auth":
      // 		instance.defaults.headers[
      // 			"Authorization"
      // 		] = `Bearer ${getSessionStorage("auth_token")}`;
      // 		break;

      // 	case "api_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("api_auth")}`;
      // 		break;

      // 	case "app_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("app_auth")}`;
      // 		break;

      // 	default:
      // 		// instance.defaults.headers["Authorization"] = `Bearer ${getCookie(
      // 		// 	"api_access_token"
      // 		// )}`;
      // 		break;
      // }

      const response: ApiResponseType = await instance.post(
        url,
        apiPayload,
        content_type
      );
      const statusCode = response?.status?.code;
      const message = response?.status?.message;
      resolve(response);
      if (message && isToast && statusCode === 200) {
        showToast(message, "success");
      }
    } catch (error) {
      reject(error);
    }
  });
};

//use for patch method
export const makePatchCall = ({
  url,
  apiPayload = {},
  isToast = false,
  baseUrl = endpoints.API_BASE_URL,
  content_type = "application/json",
  xTimezone,
}: {
  url: string;
  apiPayload?: any;
  isToast?: boolean;
  baseUrl?: string;
  content_type?: string;
  xTimezone?:string,
}) => {
  return new Promise(async (resolve: (response: any) => void, reject) => {
    try {
      instance.defaults.baseURL = baseUrl;
      instance.defaults.headers.post["Content-Type"] = content_type;
      if (xTimezone) {
        instance.defaults.headers["x-timezone"] = xTimezone;
      }

      // switch (accessTokenType) {
      // 	case "auth":
      // 		instance.defaults.headers[
      // 			"Authorization"
      // 		] = `Bearer ${getSessionStorage("auth_token")}`;
      // 		break;

      // 	case "api_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("api_auth")}`;
      // 		break;

      // 	case "app_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("app_auth")}`;
      // 		break;

      // 	default:
      // 		// instance.defaults.headers["Authorization"] = `Bearer ${getCookie(
      // 		// 	"api_access_token"
      // 		// )}`;
      // 		break;
      // }
      const response: ApiResponseType = await instance.patch(url, apiPayload);
      const statusCode = response?.status?.code;
      const message = response?.status?.message;
      resolve(response);
      if (message && isToast && statusCode === 200) {
        showToast(message, "success");
      }
    } catch (error) {
      reject(error);
    }
  });
};

//use for patch method
export const makePutCall = ({
  url,
  apiPayload = {},
  isToast = false,
  baseUrl = endpoints.API_BASE_URL,
  content_type = "application/json",
}: {
  url: string;
  apiPayload?: any;
  isToast?: boolean;
  baseUrl?: string;
  content_type?: string;
}) => {
  return new Promise(async (resolve: (response: any) => void, reject) => {
    try {
      instance.defaults.baseURL = baseUrl;
      instance.defaults.headers.post["Content-Type"] = content_type;

      // switch (accessTokenType) {
      // 	case "auth":
      // 		instance.defaults.headers[
      // 			"Authorization"
      // 		] = `Bearer ${getSessionStorage("auth_token")}`;
      // 		break;

      // 	case "api_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("api_auth")}`;
      // 		break;

      // 	case "app_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("app_auth")}`;
      // 		break;

      // 	default:
      // 		// instance.defaults.headers["Authorization"] = `Bearer ${getCookie(
      // 		// 	"api_access_token"
      // 		// )}`;
      // 		break;
      // }
      const response: ApiResponseType = await instance.put(url, apiPayload);
      const statusCode = response?.status?.code;
      const message = response?.status?.message;
      resolve(response);
      if (message && isToast && statusCode === 200) {
        showToast(message, "success");
      }
    } catch (error) {
      reject(error);
    }
  });
};

//use for delete method
export const makeDeleteCall = ({
  url,
  apiPayload = {},
  isToast = false,
  baseUrl = endpoints.API_BASE_URL,
  content_type = "application/json",
}: {
  url: string;
  apiPayload?: any;
  isToast?: boolean;
  baseUrl?: string;
  content_type?: string;
}) => {
  return new Promise(async (resolve: (response: any) => void, reject) => {
    try {
      instance.defaults.baseURL = baseUrl;
      instance.defaults.headers.post["Content-Type"] = content_type;

      // switch (accessTokenType) {
      // 	case "auth":
      // 		instance.defaults.headers[
      // 			"Authorization"
      // 		] = `Bearer ${getSessionStorage("auth_token")}`;
      // 		break;

      // 	case "api_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("api_auth")}`;
      // 		break;

      // 	case "app_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("app_auth")}`;
      // 		break;

      // 	default:
      // 		// instance.defaults.headers["Authorization"] = `Bearer ${getCookie(
      // 		// 	"api_access_token"
      // 		// )}`;
      // 		break;
      // }
      const response: ApiResponseType = await instance.delete(url, {
        data: apiPayload,
      });
      const statusCode = response?.status?.code;
      const message = response?.status?.message;
      resolve(response);
      if (message && isToast && statusCode === 200) {
        showToast(message, "success");
      }
    } catch (error) {
      reject(error);
    }
  });
};

//use for get method
export const makeGetCall = ({
	url,
	params = {},
	isToast = false,
	baseUrl = endpoints.API_BASE_URL,
	// content_type = "application/x-www-form-urlencoded",
	
}: {
	url: string;
	params?: any;
	isToast?: boolean;
	baseUrl?: string;
	// content_type?: string;
	
}) => {
	return new Promise(async (resolve: (response: any) => void, reject) => {
		try {
			instance.defaults.baseURL = baseUrl;
			// instance.defaults.headers.post["Content-Type"] = content_type;
		
			// instance.defaults.headers["Mobile-Number"] = mobileNumber;
			// switch (accessTokenType) {
			// 	case "auth":
			// 		instance.defaults.headers[
			// 			"Authorization"
			// 		] = `Bearer ${getSessionStorage("auth_token")}`;
			// 		break;

      // 	case "api_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("api_auth")}`;
      // 		break;

      // 	case "app_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("app_auth")}`;
      // 		break;

      // 	default:
      // 		// instance.defaults.headers["Authorization"] = `Bearer ${getCookie(
      // 		// 	"api_access_token"
      // 		// )}`;
      // 		break;
      // }

      const response: ApiResponseType = await instance.get(url, {
        params,
      });

      const statusCode = response?.status?.code;
      const message = response?.status?.message;
      // const nexurl = response?.next;
      resolve(response);
      if (message && isToast && statusCode === 200) {
        showToast(message, "success");
      }
    } catch (error) {
      reject(error);
    }
  });
};

//use for get method
export const makeNextPageCall = ({
  url,
  isToast = false,
  baseUrl = endpoints.API_BASE_URL,
  content_type = "application/json",
}: {
  url: string;
  isToast?: boolean;
  baseUrl?: string;
  content_type?: string;
}) => {
  
  const config: any = {
    
    
    options: {
      hideApiBaseUrl: true,
    },
  };
  return new Promise(async (resolve: (response: any) => void, reject) => {
    try {
      instance.defaults.baseURL = baseUrl;
      instance.defaults.headers.post["Content-Type"] = content_type;

      // switch (accessTokenType) {
      // 	case "auth":
      // 		instance.defaults.headers[
      // 			"Authorization"
      // 		] = `Bearer ${getSessionStorage("auth_token")}`;
      // 		break;

      // 	case "api_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("api_auth")}`;
      // 		break;

      // 	case "app_auth":
      // 		instance.defaults.headers["Authorization"] = `Bearer ${getSessionStorage("app_auth")}`;
      // 		break;

      // 	default:
      // 		// instance.defaults.headers["Authorization"] = `Bearer ${getCookie(
      // 		// 	"api_access_token"
      // 		// )}`;
      // 		break;
      // }
      const response: ApiResponseType = await instance.get(url, config);
      const statusCode = response?.status?.code;
      const message = response?.status?.message;
      resolve(response);
      if (message && isToast && statusCode === 200) {
        showToast(message, "success");
      }
    } catch (error) {
      reject(error);
    }
  });
};
