import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./router";

// import { SignUp } from '../components/signup';
import { ForgotPassword } from "../components/forgotpassword";
import { ResetPassword } from "../components/resetpassword";
import { Dashboard } from "../components/dashboard";
import { Test } from "../test";
import { ROUTES_CONSTANTS } from "../utils/constants/routesString";
import UnauthRouter from "./unauthRouter";
import { Layout } from "../container/layout";


import SignUp from "../components/SignUp/SignUp";
import Onboarding from "../components/onboarding/";
import Bookings from "../components/Bookings/Bookings";
import ViewDetails from "../components/Bookings/ViewDetails";
import MyProfile from "../components/onboarding/MyProfile";
import Schedule from "../components/onboarding/Schedule";
import PaymentMode from "../components/PaymentModes/PaymentMode";
import Terms from "../components/Policies/Terms";
import Privacy from "../components/Policies/Privacy";
import TransactionHistory from "../components/Bookings/TransactionHistory";
import SignIn from "../components/SignUp/SignIn";
import RescheduleCalender from "../components/onboarding/RescheduleCalender";
import Rescheduled from "../components/Emails/Rescheduled";
import Payment from "../components/SignUp/Payment";

import Confirmed from "../components/Emails/Confirmed";
import NoPageFound from "../components/SignUp/NoPageFound";
import ProtectedRoute from "./ProtectedRoute";

// import NotFound from 'pages/notFound';
export default class AllRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Layout>
            <Routes>
              <Route
                path={`${ROUTES_CONSTANTS.index}/:stylistcode`}
                element={<Onboarding />}
              />
              {/* <UnauthRouter exact path={ROUTES_CONSTANTS.signup} component={SignUp} />
                            <UnauthRouter exact path={ROUTES_CONSTANTS.signin} component={SignIn} />
                            <UnauthRouter exact path={ROUTES_CONSTANTS.forgotpwd} component={ForgotPassword} />
                            <UnauthRouter exact path={`${ROUTES_CONSTANTS.resetpwd}/:uid/:token`} component={ResetPassword} /> */}

              {/* <PrivateRoute
                exact
                path={ROUTES_CONSTANTS.my_profile}
                element={<MyProfile />}
              /> */}
              <Route element={<ProtectedRoute />}>
                <Route
                  path={`/:stylistcode${ROUTES_CONSTANTS.my_profile}`}
                  element={<MyProfile />}
                />
                <Route
                  path={`/:stylistcode${ROUTES_CONSTANTS.payment_modes}`}
                  element={<PaymentMode />}
                />

                <Route
                  path={`/:stylistcode${ROUTES_CONSTANTS.bookings}`}
                  element={<Bookings />}
                />
                <Route
                  path={`/:stylistcode${ROUTES_CONSTANTS.reschedule}`}
                  element={<RescheduleCalender />}
                />
                <Route
                  path={`/:stylistcode${ROUTES_CONSTANTS.rebook}`}
                  element={<RescheduleCalender />}
                />
                <Route
                  path={`/:stylistcode${ROUTES_CONSTANTS.rescheduleSucess}`}
                  element={<Rescheduled />}
                />
                <Route
                  path={`/:stylistcode${ROUTES_CONSTANTS.confirmed}`}
                  element={<Confirmed />}
                />

                <Route
                  path={`/:stylistcode${ROUTES_CONSTANTS.rebookpayment}`}
                  element={<Payment />}
                />

                <Route
                  path={`/:stylistcode${ROUTES_CONSTANTS.booking_details}`}
                  element={<ViewDetails />}
                />

                <Route
                  path={`/:stylistcode${ROUTES_CONSTANTS.transaction_history}`}
                  element={<TransactionHistory />}
                />
              </Route>

              <Route
                path={`/:stylistcode${ROUTES_CONSTANTS.signin}`}
                element={<SignIn />}
              />
              <Route
                path={`/:stylistcode${ROUTES_CONSTANTS.terms}`}
                element={<Terms />}
              />

              <Route
                path={`/:stylistcode${ROUTES_CONSTANTS.privacy}`}
                element={<Privacy />}
              />

              <Route
                path={ROUTES_CONSTANTS.no_route}
                element={<NoPageFound />}
              />

              <Route
                path={`/:stylistcode${ROUTES_CONSTANTS.signup}`}
                element={<SignUp />}
              />

              {/* <Route
                path={ROUTES_CONSTANTS.dashboard}
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              /> */}

              {/* <PrivateRoute path={ROUTES_CONSTANTS.dashboard} element={<Dashboard />} /> */}
              {/* <Route path={ROUTES_CONSTANTS.test} element={<Test />} /> */}
              {/* <Route path={ROUTES_CONSTANTS.signin} element={<SignIn />} /> */}

              {/* <PrivateRoute exact path={'/settings/:id'} component={Settings} /> */}
              {/* <PrivateRoute exact path={'/*'} component={NotFound} />  */}
            </Routes>
          </Layout>
        </Router>
      </React.Fragment>
    );
  }
}