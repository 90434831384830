import React, { useState } from "react";
import { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import Navigation from "../components/onboarding/Navigation";
import useStore from "../zustandstore";
export const Layout = (props: any) => {
  const showNav = useStore((state: any) => state.showNav);
  
  return (
    <Fragment>
      <div className="dashboard">
        <ToastContainer />
        <div className="booking-wrapper Navigation_bar">
          {showNav && <Navigation />}
        </div>

        <div
          className="loader"
          id="full_loader"
          style={{
            display: "none",
            position: "fixed",
            height: "100vh",
            width: "100%",
            top: "0px",
            left: "0px",
            backgroundColor: "#0000006e",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src="/static/loader.gif" width="100px" height="100px" alt="" />
        </div>

        {!showNav && props.children}
      </div>
    </Fragment>
  );
};
