import React from "react";
import { Navigate, Outlet, Route, useNavigate } from "react-router-dom";

import { getLocalStorage } from "../utils";

import { getURLstylistCode } from "../utils/globalFunctions";

export default function ProtectedRoute() {
  const access_token = getLocalStorage("access_token");

  return access_token ? (
    <Outlet />
  ) : (
    <Navigate to={`/${getURLstylistCode()}/signin/?notlogged`} />
  );
  // return access_token ? <Outlet /> : <SignIn />;
}
