import moment from "moment";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";
import { makeGetCall, makeNextPageCall } from "../../effects/requests";
import { BOOKING_STATUS } from "../../types";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import endpoints from "../../utils/endpoints";
import { convertLocal, getURLstylistCode, convertToTimeZoneDate, convertToTimeZoneTime } from "../../utils/globalFunctions";
import useStore from "../../zustandstore";

export default function Bookings() {
  const [allbBookingsApi, setAllbBookingsApi] = useState<any>([]);

  const [page, setPage] = useState(0);

  const [items, setItems] = useState([]);
  const [hasNext, setHasNext] = useState(true);

  const [bookingType, setBookingType] = useState(
    window.location.href.includes("rebook") ? "past" : "upcoming"
  );

  useEffect(() => {
    getResponse(setItems, items);
  }, [bookingType]);

  useEffect(() => {
    if (window.location.href.includes("rebook")) {
      setBookingType("past");
    } else {
      setBookingType("upcoming");
    }
  }, []);

  const current_date = moment.utc().format("YYYY-MM-DD HH:mm");

  const {
    setStylistUniqueCode,
    setStepNumber,
    stepNo,
    setReschedule,
    setRescheduleBookingID,
    bookingAPIData,
    setselectedDate,
  } = useStore((state: any) => state);

  const handleNavigation = (id) => {
    setReschedule(true);
    setRescheduleBookingID(id);
    // setPrevPage(2);
    // setsignin(true);
  };

  const getResponse = async (setItems, items, pageLocal = page) => {
    // setItems([]);
    // setHasNext(false);
    // setPage(0);

    const params = {
      stylist_unique_code: getURLstylistCode(),
      pagination: true,
      limit: `${pageLocal}`,
      ordering: "-start_dt",
      // offset: 0,
    };
    if (bookingType === STRING_CONSTANTS.past) {
      params["start_dt_before"] = current_date;
    } else {
      params["start_dt_after"] = current_date;
      params["is_deleted"] = false;
    }

    try {
      let apiResponse = await makeGetCall({
        url: endpoints.booking,
        params,
      });

      if (apiResponse?.next) {
        setHasNext(true);
      } else {
        setHasNext(false);
      }
      setItems([...items, ...apiResponse?.results]);
      setPage((page) => page + 10);

      // if (nextPage === true) {
      //   apiResponse = await makeNextPageCall({
      //     url: apiurl,
      //   });
      // }

      setAllbBookingsApi(apiResponse?.results);
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();

  const setShowNav = useStore((state: any) => state.setShowNav);

  const navOpen = () => {
    setShowNav(true);
  };

  return (
    <div className="booking-wrapper">
      <div className="sign_up_screen thankyou Bookings">
        <div className="upper-wrapper">
          <div className="back-logo">
            <div className="go-back my-profile">
              <div className="go-back" onClick={navOpen}>
                <img src={images.go_back} alt="" />
              </div>
            </div>
          </div>

          <div className="order-status-wrapper">
            <p>{STRING_CONSTANTS.select_booking_heading}</p>
            <div className="outer-history">
              <ul>
                <li
                  className={bookingType === "upcoming" ? "active" : ""}
                  onClick={() => setBookingType("upcoming")}
                >
                  {STRING_CONSTANTS.booking_upcoming}
                </li>
                <li
                  className={bookingType === "past" ? "active" : ""}
                  onClick={() => setBookingType("past")}
                >
                  {STRING_CONSTANTS.booking_past}
                </li>
              </ul>
            </div>

            <InfiniteScroll
              dataLength={items.length - 4} //This is important field to render the next data
              next={() => {
                getResponse(setItems, items);
              }}
              hasMore={hasNext}
              loader={
                <span className="loading_icon">
                  <img src={images.loading_icon} alt="" />
                </span>
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>{STRING_CONSTANTS.booking_no_more}</b>
                </p>
              }
            >
              <div style={{ minHeight: "" }}>
                {allbBookingsApi.map((bookings, index) => {
                  return (
                    <div key={`bookings_${index}`} className="thankyou-inner">
                      <div className="thanks-top upcoming-top">
                        <div className="Salon-name-outer">
                          <div className="order-number">
                            <span>
                              {STRING_CONSTANTS.booking_no}{" "}
                              {bookings?.booking_id}
                            </span>
                            <p>{bookings?.stylist_name}</p>
                          </div>

                          {bookings.online_status ===
                          BOOKING_STATUS.CONFIRMED ? (
                            <div className="order-status confirmed">
                              {STRING_CONSTANTS.booking_confirmed}
                            </div>
                          ) : bookings.online_status === BOOKING_STATUS.PAID ? (
                            <div className="order-status confirmed">
                              {+bookings?.deposited_amount > 0
                                ? "Paid"
                                : "confirmed"}
                            </div>
                          ) : bookings.online_status ===
                              BOOKING_STATUS.CANCELLED ||
                            bookings.is_deleted === true ? (
                            <div className="order-status confirmed cancelled">
                              {STRING_CONSTANTS.booking_cancelled}
                            </div>
                          ) : null}
                        </div>
                        <div className="date-time">
                          <div className="date">
                            <img src={images?.date_icon} alt="" />
                            {convertToTimeZoneDate(bookings?.start_dt, bookings?.timezone, "Do MMM, YYYY")}
                          </div>
                          <div className="time" key={`bookings.start_dt`}>
                            <img src={images.time_icon} alt="" />
                            {convertToTimeZoneTime(bookings.start_dt, bookings?.timezone, "hh:mm A")}-{" "}
                            {convertToTimeZoneTime(bookings.end_dt, bookings?.timezone, "hh:mm A")}
                          </div>
                        </div>
                        <div className="services-booked">
                          {/* {bookings?.services?.name} */}
                          <b>List of Services :- </b>
                          <br />
                          <br />
                          {bookings?.services?.map((service, serviceIndex) => {
                            return (
                              <>
                                {/* <span className="name">{service?.name}</span> */}
                                <span className="name">
                                  {service?.name
                                    ? (serviceIndex ? ", " : "") + service?.name
                                    : (serviceIndex ? ", " : "") +
                                      service?.service__name}
                                </span>
                              </>
                            );
                          })}
                        </div>

                        {bookings?.products?.length > 0 && (
                          <div className="product-booked services-booked">
                            {/* {bookings?.services?.name} */}
                            <b>List of Products :- </b>
                            <br />
                            <br />
                            {bookings?.products?.map(
                              (product, productIndex) => {
                                return (
                                  <>
                                    {/* <span className="name">{service?.name}</span> */}
                                    <span className="name">
                                      {product?.name
                                        ? (productIndex ? ", " : "") +
                                          product?.name
                                        : (productIndex ? ", " : "") +
                                          product?.product__name}
                                    </span>
                                  </>
                                );
                              }
                            )}
                          </div>
                        )}
                        <div className="address-map booking-address">
                          <div className="adrs">
                            <img src={images.address_icon} alt="" />

                            <span className="booking-address">
                              {bookings.address}
                            </span>
                          </div>
                        </div>
                      </div>

                      {bookingType === "upcoming" ? (
                        <div className="thanks-bottom">
                          <Link
                            to={`/${getURLstylistCode()}/reschedule/?id=${
                              bookings.id
                            }`}
                          >
                            {STRING_CONSTANTS.booking_reschedule}
                          </Link>

                          <Link
                            to={`/${getURLstylistCode()}/booking_details/?id=${
                              bookings.id
                            }`}
                            className="view-detail"
                          >
                            {STRING_CONSTANTS.booking_view_details}
                          </Link>
                        </div>
                      ) : (
                        <div className="thanks-bottom">
                          <Link
                            to={`/${getURLstylistCode()}/rebook/?id=${
                              bookings.id
                            }`}
                          >
                            {STRING_CONSTANTS.booking_rebook}
                          </Link>
                          {/* <button  onClick={() => handleNavigation(bookings.id)}>Re-schedule</button> */}

                          <Link
                            to={`/${getURLstylistCode()}/booking_details/?id=${
                              bookings.id
                            }`}
                            className="view-detail"
                          >
                            {STRING_CONSTANTS.booking_view_details}
                          </Link>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}