import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeGetCall, makePatchCall } from "../../effects/requests";
import Button from "../../global/ui/Button";
import { showToast } from "../../utils";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import endpoints from "../../utils/endpoints";
import {
  convertLocal,
  getURLstylistCode,
  getURLwithstylistCode,
  convertToTimeZoneDate,
  convertToTimeZoneTime,
} from "../../utils/globalFunctions";
import useStore from "../../zustandstore";
import Cancellation from "./Cancellation";
import Cancelled from "./Cancelled";

export default function Rescheduled() {
  const { bookingAPIData, setReschedule, setBookingId, bookingDetail } =
    useStore((state: any) => state);

  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" +
        bookingDetail.location[0] +
        "," +
        bookingDetail.location[1],
      "_blank"
    );
  };

  const { mainstylistLocationData, setStylistUniqueCode } = useStore(
    (state: any) => state
  );

  const setShowNav = useStore((state: any) => state.setShowNav);
  const navOpen = () => {
    setShowNav(true);
  };

  const [mainstylistLocationDataa, setMainstylistLocationDataa] =
    useState<any>();

  //+++++++++++
  async function getResponse() {
    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.stylist_location}?${getURLwithstylistCode()}`,
      });

      const data = await apiResponse;
      setMainstylistLocationDataa(data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getResponse();
    setReschedule(false);
  }, []);

  const [showCancel, setShowCancel] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();
  const cancelpopup = () => {
    setShowPopup(!showPopup);
  };

  const [showPopup1, setShowPopup1] = useState(false);

  const cancelpopup1 = () => {
    setShowPopup1(!showPopup1);
  };

  const [disableCancel, setDisableCancel] = useState(false);

  const handleConfirm = async () => {
    setDisableCancel(true);
    let bookingAPIPayload: any = {
      online_status: "2",
      is_deleted: "true",
    };

    let uc1 = bookingDetail?.id;

    const apiResponse = await makePatchCall({
      url: `${endpoints.booking}${uc1}/?${getURLwithstylistCode()}`,
      apiPayload: bookingAPIPayload,
    });

    if (apiResponse.status.code === 200) {
      setShowPopup(false);
      setShowCancel(true);
      navigate(
        `/${getURLstylistCode()}/booking_details/?id=${bookingDetail?.id}`
      );
    } else {
      showToast(apiResponse.status.message, "error");
    }
  };

  let cancelTimings;
  if (bookingDetail?.modified_at) {
    cancelTimings =
      moment(bookingDetail?.start_dt).subtract(
        mainstylistLocationDataa?.stylist_info?.minimum_cancellation_time_frame,
        "days"
      ) <= moment() && moment() <= moment(bookingDetail?.start_dt);
  } else {
    cancelTimings =
      moment(bookingDetail?.start_dt).subtract(
        mainstylistLocationDataa?.stylist_info?.minimum_cancellation_time_frame,
        "days"
      ) <= moment() && moment() <= moment(bookingDetail?.start_dt);
  }
  return (
    <>
      {showCancel && (
        <div className="booking-wrapper">
          {" "}
          <Cancellation bookingDetail={bookingDetail} />
        </div>
      )}

      {!showCancel && (
        <div className="booking-wrapper">
          <div className="sign_up_screen thankyou reshd-booking">
            <div className="upper-wrapper">
              <div className="back-logo">
                <div className="go-back back-profile">
                  <div className="user-icon" onClick={navOpen}>
                    <img src={images.go_back} alt="" />
                  </div>

                  {showPopup && (
                    <div className="cancel-popup">
                      <div className="overlay"></div>
                      <div className="cancel-inner">
                        <h1>{STRING_CONSTANTS.confirmed_heading}</h1>
                        <p>
                          {STRING_CONSTANTS.confirmed_sure}
                          {STRING_CONSTANTS.confirmed_final}
                          <br />
                          {mainstylistLocationDataa?.stylist_info
                            ?.cancellation_charge_amount > 0 &&
                            mainstylistLocationDataa?.stylist_info
                              ?.is_cancellation_charge_active &&
                            cancelTimings && (
                              <>
                                <br />{" "}
                                <span>{STRING_CONSTANTS.please_note}</span>
                                {
                                  bookingDetail?.cancellation_charges
                                }{" "}
                                {STRING_CONSTANTS.cancel_info}{" "}
                                {
                                  bookingDetail?.consumer_payment_method_last4_digit
                                }
                                .
                              </>
                            )}
                        </p>

                        <div className="cancel-buttons">
                          <span
                            onClick={() => handleConfirm()}
                            className={disableCancel ? "disabled" : ""}
                          >
                            Yes
                          </span>
                          <span onClick={cancelpopup}>No</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="thankyou-section">
                <div className="thankyou-inner">
                  <div className="thanks-top">
                    <div className="msg-box">
                      <img src="/assets/images/reshedule-icon.svg" alt="" />
                      <span>Booking</span>
                      <b>Rescheduled</b>
                    </div>

                    <div className="time-outer">
                      <p className="confirm-text">
                        Your booking has been successfully rescheduled please
                        see your updated details below.
                      </p>

                      <div className="date-time">
                        <div className="date">
                          <img src={images.date_icon} alt="" />

                          {convertToTimeZoneDate(bookingAPIData?.start_dt, bookingAPIData?.timezone, "Do MMM YYYY")}
                        </div>
                        <div className="time">
                          <img src={images.time_icon} alt="" />
                          {convertToTimeZoneTime(bookingAPIData?.start_dt, bookingAPIData?.timezone, "hh:mm A")}
                            - 
                          {convertToTimeZoneTime(bookingAPIData?.end_dt, bookingAPIData?.timezone,"hh:mm A")}
                        </div>
                      </div>
                    </div>
                    <div className="address-map">
                      <div className="adrs">
                        <img src={images.address_icon} alt="" />
                        {bookingDetail?.address}
                      </div>
                      <div className="map" onClick={() => showInMapClicked()}>
                        <img src={images.thanks_icon} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="thanks-bottom">
                    {STRING_CONSTANTS.booking_id_text}{" "}
                    {bookingDetail?.booking_id}
                  </div>
                </div>
                {/* <div className="thanks-bottom">
              Booking ID: {bookingDetail?.booking_id}
            </div> */}
              </div>
              <div className="service-summery">
                <span>{STRING_CONSTANTS.service_summary}</span>

                <ul>
                  {bookingDetail?.services?.map((item) => {
                    return (
                      <li key={item.service_id}>
                        <p style={{ textAlign: "left" }}>
                          <span>
                            {item.name || item.service__name}
                            {item.duration || item.service__duration
                              ? ","
                              : ""}{" "}
                            {item.duration || item.service__duration}{" "}
                            {item.duration || item.service__duration
                              ? STRING_CONSTANTS.minutes
                              : ""}
                          </span>

                          {item.total_quantity && (
                            <>
                              <br />
                              <span className="qty">
                                <img src={images.stock_icon} alt="" />{" "}
                                {STRING_CONSTANTS.stock_qty}
                                {item.stock_quantity}
                              </span>
                              <br />
                              {/* <span className="qty warehouse">
                                  <img src={images.warehouse_icon} alt="" />{" "}
                                  {STRING_CONSTANTS.warehouse_quantity}
                                  {item.warehouse_quantity}
                                </span> */}
                            </>
                          )}
                        </p>

                        <b>
                          $
                          {item.total_quantity
                            ? item.total_quantity * item.price
                            : item.price}{" "}
                        </b>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {bookingDetail?.products?.length > 0 && (
                <div className="service-summery">
                  <span>{STRING_CONSTANTS.product_heading}</span>

                  <ul>
                    {bookingDetail?.products?.map((item) => {
                      return (
                        <li key={item.product_id}>
                          <p style={{ textAlign: "left" }}>
                            <span>{item.product || item.product__name}</span>
                            <br />
                            <span className="qty">
                              <img src={images.stock_icon} alt="" />{" "}
                              {STRING_CONSTANTS.stock_qty}
                              {item.stock_quantity}
                            </span>
                            <br />
                            <span className="qty warehouse">
                              <img src={images.warehouse_icon} alt="" />{" "}
                              {STRING_CONSTANTS.warehouse_quantity}
                              {item.warehouse_quantity}
                            </span>
                          </p>
                          <b>${item.total_quantity * item.price}</b>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {mainstylistLocationDataa?.stylist_info
                ?.is_cancellation_charge_active && (
                <div className="service-summery cancel-info">
                  <span>
                    {" "}
                    <img src={images.cancel_info} alt="" />
                    {STRING_CONSTANTS.cancel_info_text}
                  </span>
                  <ul>
                    <li>
                      <span>
                        {STRING_CONSTANTS.cancel_anytime}{" "}
                        {mainstylistLocationDataa?.stylist_info
                          ?.minimum_cancellation_time_frame * 24}
                        {STRING_CONSTANTS.cancel_driven}{" "}
                        <span onClick={cancelpopup1} className="cancel-policy">
                          {STRING_CONSTANTS.payment_cancellation}
                        </span>{" "}
                        {STRING_CONSTANTS.cancel_before}
                      </span>
                      {showPopup1 && (
                        <div className="cancel-popup">
                          <div className="overlay" onClick={cancelpopup1}></div>
                          <div className="cancel-inner">
                            <h2>
                              {STRING_CONSTANTS.cancellation_policy_heading}
                            </h2>
                            <p>
                              {
                                mainstylistLocationDataa?.stylist_info
                                  ?.cancellation_policy
                              }
                            </p>
                          </div>
                        </div>
                      )}
                    </li>
                    <li>
                      <span>
                        {STRING_CONSTANTS.to_cancel}{" "}
                        <a href="javascript:void(0);" onClick={cancelpopup}>
                          {STRING_CONSTANTS.click_here}
                        </a>
                        {/* . To modify your reservation <a href="#">Click here</a>. */}
                      </span>
                    </li>
                  </ul>
                </div>
              )}
              <div className="service-summery cancel-info need-assist">
                <span>
                  <img src={images.need_assist_icon} alt="" />
                  {STRING_CONSTANTS.need_assist}
                </span>
                <ul>
                  <li>
                    <span>
                      {STRING_CONSTANTS.contact_us_via}{" "}
                      <a
                        href={`mailto:${mainstylistLocationDataa?.stylist_info?.email}`}
                      >
                        {mainstylistLocationDataa?.stylist_info?.email}
                      </a>
                      {STRING_CONSTANTS.or_over_phone}{" "}
                      <a
                        href={`tel:${mainstylistLocationDataa?.stylist_info?.mobile_number}`}
                      >
                        {mainstylistLocationDataa?.stylist_info?.mobile_number}
                      </a>
                      .
                    </span>
                  </li>
                </ul>
              </div>
              <div className="service-summery need-assist follow-social">
                {mainstylistLocationDataa?.stylist_info?.instagram_handle ||
                mainstylistLocationDataa?.stylist_info?.facebook_handle ||
                mainstylistLocationDataa?.stylist_info?.tiktok_handle ? (
                  <span>
                    Follow {mainstylistLocationDataa?.stylist_info?.full_name}{" "}
                    socials
                  </span>
                ) : null}

                <ul className="social-links">
                  {mainstylistLocationDataa?.stylist_info?.instagram_handle && (
                    <li>
                      <a
                        href={
                          mainstylistLocationDataa?.stylist_info
                            ?.instagram_handle
                        }
                        target="_blank"
                      >
                        <img src={images.insta_icon} alt="" />
                      </a>
                    </li>
                  )}
                  {mainstylistLocationDataa?.stylist_info?.facebook_handle && (
                    <li>
                      <a
                        href={
                          mainstylistLocationDataa?.stylist_info
                            ?.facebook_handle
                        }
                        target="_blank"
                      >
                        <img src={images.fb_icon} alt="" />
                      </a>
                    </li>
                  )}

                  {mainstylistLocationDataa?.stylist_info?.tiktok_handle && (
                    <li>
                      <a
                        href={
                          mainstylistLocationDataa?.stylist_info?.tiktok_handle
                        }
                        target="_blank"
                      >
                        <img src={images.tiktok_icon} alt="" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>

              <div className="buttons">
                <Button
                  className="continue-btn re-sch"
                  type="submit"
                  btnTxt={STRING_CONSTANTS.cancel_booking}
                  isLoading={false}
                  onClick={cancelpopup}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
