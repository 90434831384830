/* eslint-disable import/no-anonymous-default-export */
export default {

  API_BASE_URL: process.env.REACT_APP_API_URL,

  stylist_location: "stylist-location/",
  stylist_services: "st-service/",

  check_number: "check-number/",
  otp: "otp/",
  available_timing: "get-available-timing/",
  booking: "booking/",
  register: "register/",
  payment_methods:"payment-methods/",
  profile:"profile/",
  terms:"terms/",
  privacy_policy:"privacy-policy/",
  timezone:'get-timezone/',
  get_full_days: 'bookings/full-days/'
};
