import React, { useEffect, useState } from "react";
import { makeGetCall } from "../../effects/requests";
import { BOOKING_STATUS } from "../../types";
import { showToast } from "../../utils";

import endpoints from "../../utils/endpoints";
import {
  getURLstylistCode,
  getURLwithstylistCode,
} from "../../utils/globalFunctions";
import useStore from "../../zustandstore";
import Cancellation from "../Emails/Cancellation";
import Confirmed from "../Emails/Confirmed";
import Rescheduled from "../Emails/Rescheduled";
import NoBookingFound from "./NoBookingFound";

export default function ViewDetails() {
  const [bookingDetail, setBookingDetail] = useState<any>();
  const [noBooking, setNoBooking] = useState(false);
  const {
    setBookingId,
    stylistUniqueCode,
    setStylistUniqueCode,
    // bookingDetail,
    // setBookingDetail,
    bookingAPIData,
  } = useStore((state: any) => state);

  async function getResponse() {
    const urlParams = new URLSearchParams(window.location.search);

    let uc1 = urlParams.get("id");

    setBookingId(urlParams.get("id"));

    // const params = {
    //   stylist_unique_code: ,
    // };

    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.booking}${uc1}/?${getURLwithstylistCode()}`,

        // params,
      });

      setBookingDetail(apiResponse?.data);

      const data = apiResponse;

      if (data.status.code != 200) {
        // showToast(data.status.message, "error");
        setNoBooking(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getResponse();
  }, []);

  return (
    <div className="booking-wrapper">
      {bookingDetail?.online_status === BOOKING_STATUS.CONFIRMED ||
      bookingDetail?.online_status === BOOKING_STATUS.PAID ? (
        <div className="order-status confirmed">
          <Confirmed
            bookingDetail={bookingDetail}
            setBookingId={setBookingId}
            stylistUniqueCode={stylistUniqueCode}
          />
        </div>
      ) : bookingDetail?.online_status === BOOKING_STATUS.CANCELLED ||
        bookingDetail?.is_deleted === true ? (
        <div className="order-status confirmed cancelled">
          <Cancellation
            bookingDetail={bookingDetail}
            setBookingId={setBookingId}
            stylistUniqueCode={stylistUniqueCode}
          />
        </div>
      ) : null}

      {noBooking && <NoBookingFound />}
    </div>
  );
}
