import { AxiosResponseHeaders, AxiosRequestConfig } from "axios";

export type CookieType = {
    accessToken?: string;
};

export type CookiesKeysType =
    | "user"
    | "access_token"
    | "refresh_token"
    | "data"
    | "user_data"
    | "api_access_token"
    | "bearerAuth";

export type ApiResponseType<T = any, D = any> = {
    data: T;
    status: StatusType;
    statusText: string;
    headers: AxiosResponseHeaders;
    config: AxiosRequestConfig<D>;
    request?: any;
};

export type StatusType = {
    code: number;
    message: string | any;
};

export enum BUTTON_TYPE {
    TYPE_SUBMIT = "submit",
    TYPE_BUTTON = "button",
    TYPE_RESET = "reset",
}

export enum VARIANT_TYPE {
    TYPE_PRIMARY = "primary",
    TYPE_SECONDARY = "secondary",
}

export enum USER_TYPE {
    TYPE_USER = "user",
    TYPE_MANAGER = "manager",
}

export enum USER_ACTION_TYPE {
    TYPE_CREATE = "create",
    TYPE_UPDATE = "update",
    TYPE_DELETE = "delete",
}

export enum NUMBER_STATUS {
    STATUS_0 = 0,
    STATUS_1 = 1,
    STATUS_2 = 2,
    STATUS_3 = 3,
    STATUS_4 = 4,
    STATUS_5 = 5,
    STATUS_6 = 6,
    STATUS_7 = 7,
    STATUS_8 = 8,
    STATUS_9 = 9,
    STATUS_10 = 10,
}


export enum BOOKING_STATUS {
    CONFIRMED = 0,
    PAID = 1,
    CANCELLED = 2,    
}
//Responses Type

export interface ResponseType<T> {
    status?: any;
    data: T;
}

export type LoginResType = {
    Token: {
        AUTH_HEADER_TYPES: Array<string>;
        refresh: string;
        access: string;
    };
};

export type EmpDetailResType = {
    boide_id: string | any;
    branch: string;
    company: string;
    email: string;
    face_image: null;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: string;
    phone_number: any;
    unique_id?: any;
};

export type SuccessResType = {
    Success?: any;
    message?: string | any;
};

export type UserParams = {
    search?: string | any;
};

export type DateTypes =
    | "MM/DD/YYYY"
    | "YYYY-MM-DD HH:mm"
    | "YYYY-MM-DD"
    | "DD / MM / YYYY"
    | "YYYY-MM-DD HH:mm:ss"
    | "dddd"
    | "DD MMMM"
    | "YYYY"
    | "Do MMM"
    | "ddd, M MMMM"
    | "ddd"
    | "ddd, D MMMM"
    | "Do MMMM YYYY"
    | "Do MMMM";

export type TimeTypes = "HH:mm" | "hh:mm A" | "h:mm" | "HH:mm:ss";

export type GenerateFormKeysType =
    | "start_date"
    | "start_time"
    | "end_date"
    | "end_time";

export type GenerateFormDataType = {
    start_date: string | any;
    start_time: string | any;
    end_date: string | any;
    end_time: string | any;
};

export type AddEmpResType = {
    Success?: SuccessResType;
    data?: EmpDetailResType;
    message?: string | any;
};


export enum API_HEADERS {
    CONTENT_TYPE = "Content-Type",
    AUTHORIZATION = "Authorization",
    TYPE_FORM_DATA = "multipart/form-data",
    TYPE_RAW_DATA = "application/json",
    TYPE_MULTIPART_DATA = "multipart/form-data",
    TYPE_PLAIN_TEXT="text/plain",
    TOKEN_TYPE = "Bearer"
}