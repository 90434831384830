export const images = {
    // Onboarding Screen
    date_icon: '/assets/images/date-icon.svg',
    go_back: '/assets/images/svgs/go-back.svg',
    time_icon:'/assets/images/time-icon.svg',
    address_icon:'/assets/images/address-icon.svg',
    cancel_icon:'/assets/images/cancell-icon.svg',
    thanks_icon:'/assets/images/thanksmap-icon.svg',
    need_assist_icon:'/assets/images/svgs/need-assist.svg',
    insta_icon:'/assets/images/insta.svg',
    fb_icon:'/assets/images/fb.svg',
    twitter_icon:'/assets/images/twitter.svg',
    tiktok_icon:'/assets/images/svgs/tik-tok-icon.svg',
    linkedin_icon:'/assets/images/linkedin.svg',
    user_1:'/assets/images/user-icon-1.svg',
    confirm_icon:'/assets/images/confirm-icon.svg',
cancel_info:'/assets/images/svgs/cancel-info.svg',
right_arrow_icon:'/assets/images/nav-right-arrow.svg',
new_booking:'/assets/images/svgs/new-booking-icon.svg',
del_card_icon:'/assets/images/svgs/del-card-icon.svg',
go_cart:'/assets/images/svgs/go-cart.svg',
call_icn:'/assets/images/svgs/call-icn.svg',
address_icn:"/assets/images/svgs/address-icn.svg",
warehouse_icon:'/assets/images/svgs/warehouse-img.svg',
stock_icon:'/assets/images/svgs/stock-img.svg',
loading_icon:'/assets/images/gifs/loading.gif',
my_prfl:'/assets/images/svgs/myprfl.svg',
my_cards:'/assets/images/svgs/my-cards.svg',
my_booking:'/assets/images/svgs/my-booking.svg',
privacy_icon:'/assets/images/svgs/privacy-icon.svg',
terms_icon:'/assets/images/svgs/terms-icon.svg',
history:'/assets/images/svgs/t-history.svg',
sign_out:'/assets/images/svgs/sign-out.svg'
  }

  

