export const apiConstants = {
  data_key: "data",
  token_key: "token",
  docs_key: "docs",
  total_key: "total",
  limit_key: "limit",
  page_key: "page",
  pages_key: "pages",
  status_key: "status",
  access_token_key: "access",
  refresh_token_key: "refresh",
  user_data_key: "data",

  next_page_url_key: "next",
  previous_page_url_key: "previous",
  count_key: "count",

  email: "email",
  first_name: "first_name",
  last_name: "last_name",
  mobile_number: "mobile_number",
  country_code: "country_code",
  // feature_msg: "feature_msg",
  calander: "calander",
  time: "time",
  otp_number: "otp_number",
  location: "location",
  custom_location: "custom_location",
  services: "services",
  deposited_amount:"deposited_amount",
  cancellation_charges:"cancellation_charges",
  salon_name: "salon_name",
  lat: "lat",
  lng: "lng",
  address: "address",
  terms_check: "terms_check:",
  zip_code: "zip_code",
  city:"city",
  country:"country",
  
  state:"state",
  state_code:"state_code",
  location_image:"location_image",
timezone:"timezone",
  custom_location_object:"custom_location_object",
  
};

export const errorMessage = {
  fieldRequired: (text: string) => `Please enter ${text}.`,
  selectFieldRequired: (text: string) => `Please select ${text}.`,

  phone_validation: "Phone number is not valid",
  email_validation: "Email is not valid.",
  empty_login_pin: "Please enter login pin.",
  server_error: "Server Error. Please try after some time.",
};
