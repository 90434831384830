import { useState, useEffect } from "react";

import useStore from "../zustandstore";
let YOUR_API_KEY = "AIzaSyAQQuGSFwzVG6c8BObzq1Rxcd1dcKwRD8I";
// function useCurrentAddress() {

const useCurrentAddress = () => {
  const [position, setPosition] = useState({});
  const [curraddress, setCurraddress] = useState<string | null>(null);
  const [currAddressComponent, setCurrAddressComponent] = useState({
    zip_code: "",
    city: "",
    country: "",
    country_code: "",
    state_code: "",
    state: "",
  });

  const [error, setError] = useState<any>(null);
  const [lat, setLat] = useState<any>(null);
  const [lng, setLng] = useState<any>(null);

  const { locationNotFound, setLocationNotFound } = useStore(
    (state: any) => state
  );

  const onChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };

  const onError = (error) => {
    setError(error.message);

    setLocationNotFound(true);
  };

  const successCallback = (position) => {
    const { latitude, longitude } = position.coords;

    setLat(latitude);
    setLng(longitude);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${YOUR_API_KEY}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCurraddress(data.results[0].formatted_address);
        const address_components = data.results[0]?.address_components;

        let postalCode,
          city = "Sydney",
          country = "Australia",
          country_code = "AU",
          state_code = "NSW",
          state = "New South Wales";

        address_components.map((component: any) => {
          if (component.types.includes("postal_code")) {
            postalCode = component.short_name || component.long_name;
          }

          if (component.types.includes("country")) {
            country = component.long_name;
            country_code = component.short_name;
          }

          if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("administrative_area_level_2")) {
            city = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            city = component.long_name;
          }

          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
            state_code = component.short_name;
          }
        });

        const addressInfo = {
          zip_code: postalCode || "2000",
          city: city,
          country: country,
          country_code: country_code,
          state_code: state_code,
          state: state,
        };

        setCurrAddressComponent(addressInfo);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const errorCallback = (error) => {
    setError(error);
    // alert(error.message)
  };

  const getLocationParameter = async () => {
    const geo = await navigator.geolocation;

    if (!geo) {
      setError("Geolocation is not supported");
      return;
    }

    geo.getCurrentPosition(successCallback, errorCallback, {
      // maximumAge: 60000,
      // timeout: 10000,
      enableHighAccuracy: true,
    });

    const watcher = geo.watchPosition(onChange, onError);

    return () => geo.clearWatch(watcher);
  };

  useEffect(() => {
    getLocationParameter();
  }, []);

  
  return {
    ...position,
    ...currAddressComponent,
    curraddress,
    lat,
    lng,
    error,
  };
};

export default useCurrentAddress;
