import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeDeleteCall, makeGetCall } from "../../effects/requests";
import { images } from "../../utils/constants/assets";
import endpoints from "../../utils/endpoints";
import useStore from "../../zustandstore";
import AddShowCard from "./AddShowCard";

const stripeKey: any = process.env.REACT_APP_SECRET_KEY;
const stripePromise = loadStripe(stripeKey);

const PaymentMode = () => {
  const renderAddNewCard = () => {
    return (
      <div>
        <Elements stripe={stripePromise}>
          <AddShowCard />
        </Elements>
      </div>
    );
  };

  const navigate = useNavigate();
  const setShowNav = useStore((state: any) => state.setShowNav);

  const navOpen = () => {
    setShowNav(true);
  };

  return (
    <div>
      <div className="booking-wrapper payment_modes">
        <div className="upper-wrapper">
          <div className="back-logo">
            <div className="go-back" onClick={navOpen}>
              <img src={images.go_back} alt="" />
            </div>
          </div>

          <h1>Payment Modes</h1>
          <p>View your saved card or add new card.</p>
          {renderAddNewCard()}
        </div>
      </div>
    </div>
  );
};

export default PaymentMode;
