

export const STRING_CONSTANTS = {
    // app_name: Config.APP_NAME,
    ok_text: "ok",

    required_validation: (name: string) => `${name} is required!`,
    invalid_email_msg: "Must be formatted: john.doe@email.com",
    invalid_pass_msg: "Must contain minimum eight characters, at least one letter and one number",
    invalid_pass_special_msg: "Must contain minimum eight characters, at least one letter, one number and one special character",

    connection_offline: "Your connection seems Offline",
    connection_online: "You are back online",
    connection_offline_description:
        "Cannot proceed with the operation.\nPlease check your Internet connection.",


    // Permission related strings
    permission_required_text: "Permission Required!",
    settings_text: "Settings",
    cancel_text: "Cancel",
    abc: "abc",
    xyz: "xyz",

    enter_the_otp: 'Please enter the OTP',
    enter_valid_otp: 'Please enter the valid OTP',
    Verification_code_expire: 'Verification code expired.',
    invalid_number_entered: "Invalid number entered",
    booking_details:"booking_details",
    
    cancel_info:"will be charged upon cancellation with the remainder refunded to your original card ending with",
cancel_anytime:"You can cancel anytime up until",
cancel_driven:" hours (this is driven by",
cancel_before:") before your appointment",
otp_sign:"Sign in to complete your booking with",
enter_otp:"Enter OTP to complete your booking with",
new_here:"New here? or are you an existing customer?",
terms_heading:"Terms & Conditions",
by_continue:"By continuing you accept the ",
review_your_appointment:"Review your appointment details & tell us if they’re any additional details for your booking.",
confirmed_heading:"Are you sure you want to cancel your booking?",
confirmed_sure:"Are you sure you’d like to cancel, your cancellation will be ",
confirmed_final:"final & your stylist will be notified.",
confirmed_appointment:"Your appointment confirmation has been sent to your email address.",
you_will:"You will receive a booking confirmation email/text once",
approves_your:"approves your booking.",
success_visit:"You can visit my bookings to cancel the booking",
payment_cancellation:"cancellation policy.",
balance_due:"Balance due after services",
privacy_heading:"Privacy Policy",
current_location_alert:"To use current location in mobile booking give location permission/turn your location on ",
signup_form:"I agree to the",
terms:"terms",
privacy:"privacy policy",
country_invalid:"Country code is Invalid",
new_booking:"New Booking",
select_booking_heading:'Select upcoming or past to view your bookings.',
booking_upcoming:'Upcoming',
booking_past:'Past',
booking_no_more:'No Bookings to load',
booking_confirmed:'confirmed',
booking_cancelled:'Cancelled',
booking_view_details:'View details',
booking_reschedule:'Re-schedule',
booking_rebook:'Rebook',
your_location_is_off:'Your location is off/blocked to use current location in mobile booking turn your location on and refresh the page',
this_booking_requires:'This booking requires a deposit. You will be charged when your booking is accepted and the amount  will be applied to your account and deducted off the total bill on the day of the service.',
deposit_heading:'Deposit Required',
or_over_phone:'or over the phone:',
appointment_booking:'Appointment booking',
appointment_requested:'Appointment Requested',
appointment_confirmed:'Appointment Confirmed',
need_assist:'Need assistance?',
contact_us_via:'Contact us via email:',
we_are_done:'We’re almost done!',
appoint_summery:'Appointment Summary',
location_text:'Location',
booking_req:'Booking Requested!',
booking_err:'Booking Error',
are_sure:'Are you sure?',
thank_booking:'Thank you for your booking',
please_correct:'Please enter valid card details',
you_want_delete:'Are you sure you want to delete the card',
select_date:'Please select date',
available_time:'Available time for',
Payment_Method:'Payment Method',
please_note:'Please note: A cancellation fee of $',
booking_no:'Booking No:',
service_summary:'Service Summary',

product_heading:'Product Summary',
cancel_booking:'Cancel Booking',
confirmation_text:'Confirmation',
requested_text:'Requested',
to_cancel:'To cancel your booking',
click_here:'Click here',
cancel_info_text:'Cancellation information',
cancellation_policy_heading:'Cancellation Policy',
apppointment_request:'Appointment Request',
booking_id_text:'Booking ID:',
minutes:'minutes',
deposit_info:'Deposit information',
additional_info:'Additional info',
approx_total:'Approximate total',
confirm_text:'Confirm',
date_time:'Date & Time',
stock_qty:'Stock qty : ',
total_qty:'Qty : ',
warehouse_quantity:'Warehouse qty : ',
no_booking_found:'There is no booking associated with this user. We kindly request you to log out using the provided icon and then log in again.',
past:"past"
}