import moment from "moment";
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { makeGetCall } from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import useStore from "../../zustandstore";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  convertLocal,
  getURLstylistCode,
  getURLwithstylistCode,
} from "../../utils/globalFunctions";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
export default function TransactionHistory() {
  const navigate = useNavigate();

  const [allbBookingsApi, setAllbBookingsApi] = useState<any>([]);

  const [page, setPage] = useState(0);

  const [items, setItems] = useState([]);
  const [hasNext, setHasNext] = useState(true);

  const [bookingType, setBookingType] = useState("upcoming");
  const current_date = moment().format("YYYY-MM-DD hh:mm:ss");

  useEffect(() => {
    getResponse(setItems, items);
  }, [bookingType]);

  const { setStylistUniqueCode } = useStore((state: any) => state);

  const getResponse = async (setItems, items, pageLocal = page) => {
    const params = {
      stylist_unique_code: getURLstylistCode(),
      pagination: true,
      limit: `${pageLocal}`,
      ordering: `-deposit_paid_dt`,
      // offset: 0,
    };
    if (bookingType === STRING_CONSTANTS.past) {
      params["start_dt_before"] = current_date;
    }

    try {
      let apiResponse = await makeGetCall({
        url: endpoints.booking,
        params,
      });

      if (apiResponse?.next) {
        setHasNext(true);
      } else {
        setHasNext(false);
      }
      setItems([...items, ...apiResponse?.results]);
      setPage((page) => page + 10);

      // if (nextPage === true) {
      //   apiResponse = await makeNextPageCall({
      //     url: apiurl,
      //   });
      // }

      setAllbBookingsApi(apiResponse?.results);
    } catch (err) {
      console.log(err);
    }
  };

  const setShowNav = useStore((state: any) => state.setShowNav);

  const navOpen = () => {
    setShowNav(true);
  };

  return (
    <div className="booking-wrapper">
      <div className="step_5 sign_up_screen">
        <div className="upper-wrapper">
          <div className="back-logo">
            <div className="go-back" onClick={navOpen}>
              <img src={images.go_back} alt="" />
            </div>
          </div>

          <h1>Transaction History</h1>
          <p>View your full transaction history below.</p>
          <br />

          <InfiniteScroll
            dataLength={items.length} //This is important field to render the next data
            next={() => {
              getResponse(setItems, items);
            }}
            hasMore={hasNext}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b></b>
              </p>
            }
          >
            <ul
              className="transction_history_list"
              style={{ minHeight: "100vh" }}
            >
              {allbBookingsApi.map((bookings, index) => {
                return (
                  <li>
                    <p>
                      {bookings?.stylist_name}
                      <span>
                        {convertLocal(bookings?.created_at, "Do MMM, YYYY")} |{" "}
                        {convertLocal(bookings.created_at, "hh:mm A")}
                      </span>
                    </p>
                    <b>
                      $ {""}
                      {bookings?.deposited_amount}
                    </b>
                  </li>
                );
              })}
            </ul>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
