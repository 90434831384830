import React, { Component } from "react";
import { googleKey } from "../../utils/constants/googlekey";
import InputField from "./InputField";
class GooglePlacesSearchBar extends Component<Readonly<any>, any> {
  autocompleteInput: any = React.createRef();
  autocomplete: any = null;

  constructor(props: Readonly<any>) {
    super(props);
    this.state = {};
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }

  componentDidMount() {
    if (googleKey) {
      // Search address field should be for AU region only
      const options = {
        componentRestrictions: { country: "au" },
      };
      this.autocomplete = new google.maps.places.Autocomplete(
        this.autocompleteInput.current,
        options
      );
      this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
    }
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    if (place && place.geometry) {
      let postalCode = 2000,
        region = "",
        country = "",
        city = "",
        country_code = "",
        state = "",
        state_code = "";

      const address_components = place.address_components;

      console.log(address_components, "address_components");

      address_components.map((component: any) => {
        if (component.types.includes("postal_code")) {
          postalCode = component.short_name || component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          region = component.short_name || component.long_name;
        }

        if (component.types.includes("country")) {
          country = component.long_name;
          country_code = component.short_name;
        }

        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("administrative_area_level_2")) {
          city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          city = component.long_name;
        }

        if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
          state_code = component.short_name;
        }
      });
      this.props.onChangeLocation(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
        place.formatted_address,
        postalCode,
        region,
        country,
        city,
        country_code,
        state,
        state_code
      );
    }
  }

  render() {
    return (
      <div className="form_group google_search_bar">
        <div className="input_box">
          <InputField
            reference={this.autocompleteInput}
            id="autocomplete"
            name={this.props.name}
            value={this.props.value ? this.props.value : ""}
            control={this.props.control}
            type="text"
            // label={this.props.label}
            errors={this.props.errors}
            className={this.props.className}
            placeholder={this.props.placeholder}
          />
        </div>
      </div>
    );
  }
}

export default GooglePlacesSearchBar;
