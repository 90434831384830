import React, { useEffect, useState } from "react";
import ScheduleDate from "./ScheduleDate";
import { apiConstants } from "../../utils/constants/apiConstants";
import ScheduleTime from "./ScheduleTime";

import useStore from "../../zustandstore";
import Button from "../../global/ui/Button";
import {
  getURLwithstylistCode,
  isNotEmpty,
  isNotEmptyString,
} from "../../utils/globalFunctions";
import { makeGetCall, makePatchCall } from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { getLocalStorage } from "../../utils";
interface ScheduleProps {
  handleNext: () => void;
  control?: any;
  errors?: any;
  watch?: any;
  handlePrevious: () => void;
  setSelectedDate?: any;
  selectedDate?: any;
  name?: any;
  setValue?: any;

  setSelectedIndex?: any;
  selectedIndex?: any;

  // isValid?: any;
  handleDatesChange?: any;
  focusedInput?: any;
  setFocusedInput?: any;
  handleTimeChange?: any;
  getValues?: any;
  setStepNo?: any;
}

const Schedule = (props: ScheduleProps) => {
  const {
    control,
    errors,
    handleNext,
    getValues,
    handlePrevious,
    setValue,
    selectedIndex,
    setSelectedIndex,
    focusedInput,
    setFocusedInput,
    handleDatesChange,
    handleTimeChange,

    setStepNo,
  } = props;
  const [timingError, setTimingError] = useState(false);

  const [timingApi, setTimingApi] = useState<any>("");

  const [hasError, setHasError] = useState(true);
  const [isDateSelected, setDateSelected] = useState(false);

  const {
    selectedTime,
    stylistUniqueCode,
    selectedDate,
    reschedule,
    rescheduleBookingID,
    bookingDuration,
    bookingAPIData,
    tz,
    setBookingAPIData,
    setSelectedDate,
    stylistLocationData,
    setStylistLocationData,
    location_id,
  } = useStore((state: any) => state);

  async function getResponse() {
    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.stylist_location}?${getURLwithstylistCode()}`,
      });

      const data = await apiResponse;
      setStylistLocationData(data);
    } catch (err) {
      console.log(err);
    }
  }

  async function mblBookingResponse() {
    try {
      const apiResponse = await makeGetCall({
        url: `${
          endpoints.stylist_location
        }?${getURLwithstylistCode()}&only_mobile_booking=true`,
      });

      const data = await apiResponse;
      setStylistLocationData(data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (getValues(apiConstants.location) != "mbl_booking") {
      getResponse();
    } else {
      mblBookingResponse();
    }
  }, []);

  const mainstylistLocationData = useStore(
    (state: any) => state.stylistLocationData
  );


  const getaddress = () => {
    const selectedData = mainstylistLocationData?.results?.find((item) => {
      return item.id === getValues(apiConstants.location);
    });
    return selectedData;
  };

  //+++++Calculate Date and Time
  // if (reschedule) {
  // }
  const handleSubmit = () => {
    if (!reschedule) {
      handleNext();
    }
    if (reschedule) {
      const time = `${selectedTime
        .trim()
        .slice(0, 7)
        .replace(/\s/g, "")} ${selectedTime.slice(8)}`;
      const date = new Date(`01/01/2022 ${selectedTime}`);
      const startTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      const start_dt = `${selectedDate} ${startTime}`;
      //++++calculate end time
      const [hours, minutes] = startTime.split(":"); // split the input time into hours and minutes
      const tempDate = new Date();
      tempDate.setHours(parseInt(hours)); // set the hours of the date object to the hours from the input time
      tempDate.setMinutes(parseInt(minutes) + bookingDuration.duration); // add the duration to the minutes of the date object
      const endTime = tempDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      const end_dt = `${selectedDate} ${endTime}`;
      makePatchCall({
        url: `${
          endpoints.booking
        }${rescheduleBookingID}/?${getURLwithstylistCode()}`,
        apiPayload: {
          start_dt: start_dt,
          end_dt: end_dt,
          // consumer: bookingAPIData.consumer.id,
        },
        xTimezone: bookingAPIData?.timezone,
      }).then((res) => {
        setStepNo(9);
        setBookingAPIData(res?.data);
      });
    }
  };

  const getFormattedMblNumber = () => {
    let num = mainstylistLocationData?.stylist_info?.mobile_number?.slice(3);
    let countrycode =
      mainstylistLocationData?.stylist_info?.mobile_number?.slice(0, 3);

    if (num?.length === 9) {
      num = num?.slice(0, 3) + " " + num?.slice(3, 6) + " " + num?.slice(6);
    } else {
      num = num?.slice(0, 4) + " " + num?.slice(4, 7) + " " + num?.slice(7);
    }
    return countrycode + " " + num;
  };

  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" +
        getaddress()?.location[0] +
        "," +
        getaddress()?.location[1],
      "_blank"
    );
  };

  const setShowNav = useStore((state: any) => state.setShowNav);

  const navOpen = () => {
    setShowNav(true);
    setDateSelected(false);
    setSelectedDate(false);
  };

  const access_token = getLocalStorage("access_token");
  const goback = () => {
    setValue(apiConstants.calander, null);
    setSelectedDate(null);
    // setValue(apiConstants.time, "");

    handlePrevious();
  };

  useEffect(() => {
    // if (selectedDate === null || isDateSelected === true) {
    //   setSelectedDate(false);
    // }
  }, []);

  return (
    <div className="step_3 sign_up_screen">
      <div className="upper-wrapper">
        <div className="go-back">
          <div onClick={goback}>
            {reschedule ? "" : <img src={images.go_back} alt="" />}
          </div>

          {access_token ? (
            <div className="user-icon" onClick={navOpen}>
              <img src={images.user_1} alt="" />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="prsn-details-outer">
          <div className="prsn-img">
            <img
              src={mainstylistLocationData?.stylist_info?.profile_picture}
              alt=""
            />
          </div>
          <div className="prsn-details">
            <h1>{mainstylistLocationData?.stylist_info?.full_name}</h1>
            {getValues(apiConstants.location) === "mbl_booking" ? (
              ""
            ) : (
              <p className="features-address">
                <img src={images.address_icn} alt="" />
                {/* {getValues(apiConstants.location) === "mbl_booking"
                ? getValues(apiConstants.custom_location)
                : getaddress()?.address} */}
                <span onClick={() => showInMapClicked()}>
                  {getaddress()?.address}
                </span>
              </p>
            )}

            {isNotEmpty(
              mainstylistLocationData?.stylist_info?.mobile_number
            ) && (
              <p className="ph-number">
                <img src={images.call_icn} alt="" />
                {getFormattedMblNumber()}
              </p>
            )}
          </div>
        </div>

        {getValues(apiConstants.location) === "mbl_booking" && (
          <div className="mbl-booking-box">
            <div className="icon">
              <img src={images.go_cart} alt="" />
            </div>

            <div>
              <p>{getValues(apiConstants.custom_location)}</p>
              <span>Mobile Booking</span>
            </div>
          </div>
        )}
        <p className="appoint-text">Select your appointment date & time.</p>

        <div className="Calender-picker">
          <ScheduleDate
            setDateSelected={setDateSelected}
            control={control}
            name={apiConstants.calander}
            errors={errors}
            setValue={setValue}
            setSelectedIndex={setSelectedIndex}
            focusedInput={focusedInput}
            setFocusedInput={setFocusedInput}
            handleDatesChange={handleDatesChange}
            setTimingError={setTimingError}
            getValues={getValues}
            setTimingApi={setTimingApi}
          />

          <div className="timing-bottom-mb">
            {timingError ? (
              <span className="pls-date">{timingApi?.status?.message}</span>
            ) : // ) : (isDateSelected || selectedDate) && timingApi ? (
            // isDateSelected || selectedDate
            getValues(apiConstants.calander) ? (
              <ScheduleTime
                control={control}
                name={apiConstants.time}
                errors={errors}
                setValue={setValue}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                // handleTimeChange={handleTimeChange}
                getValues={getValues}
                timingError={timingError}
              />
            ) : (
              <span className="pls-date">{STRING_CONSTANTS.select_date}</span>
            )}

            <div className="bottom-wrapper bottom-shadow">
              <Button
                // className="continue-btn"
                disabled={selectedDate && selectedIndex ? false : true}
                className={hasError ? "error continue-btn" : "continue-btn"}
                type="submit"
                btnTxt={"Confirm Time & Date"}
                // isLoading={false}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
