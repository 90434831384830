import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apiConstants } from "../../utils/constants/apiConstants";
import { locationSelectorForm } from "../../utils/formConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import Features from "./Features";
import Location from "./Location";
import SignUp from "../SignUp/SignUp";
import Otp from "../SignUp/Otp";
import Payment from "../SignUp/Payment";


import { useNavigate } from "react-router-dom";
import Success from "../Emails/Success";
import Rescheduled from "../Emails/Rescheduled";
import {
  calanderValidation,
  featuresValidation,
  locationValidation,
  noValidation,
  phoneValidation,
  signupValidation,
} from "./ValidationSchema";

import { FocusedInputShape } from "react-dates";
import SignUpForm from "../SignUp/SignUpForm";
import Schedule from "./Schedule";
import useStore from "../../zustandstore";
import { makeGetCall } from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import Bookings from "../Bookings/Bookings";
import PaymentMode from "../PaymentModes/PaymentMode";
import Navigation from "./Navigation";
import { getURLstylistCode } from "../../utils/globalFunctions";
import { getLocalStorage } from "../../utils";
// import PaymentTest from "../SignUp/PaymentTest";

export default function Onboarding() {
  // const {stepNo, increasestepNo, removestepNo, decreasestepNo } = stepStores<any>((state: any) => state)
  const validator: any = {
    "1": locationValidation,
    "2": featuresValidation,
    "3": calanderValidation,
    "4": phoneValidation,
    "5": noValidation,
    "6": signupValidation,
    "7": noValidation,
    "8": noValidation,
    "9": noValidation,
  };

  const navigate = useNavigate();
  const [stepNo, setStepNo] = useState(1);

  const { stylistUniqueCode, setStylistUniqueCode, stepNoFunc, setStepNumber } =
    useStore((state: any) => state);
  const {
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: locationSelectorForm,

    // resolver: yupResolver(validator[stepNo.toString()]),
    resolver: yupResolver(validator[stepNo]),
    // resolver: yupResolver(stepThreeValidation),
    mode: "onChange",
  });

  const handlePrevious = () => {
    if (reBook) {
      setStepNo(2);
    } else {
      if (prevPage && Number(prevPage) > 0) {
        setStepNo(Number(prevPage));
        setPrevPage(false);
      } else {
        setStepNo(stepNo - 1);
      }

      if (stepNo === 7 && access_token) {
        setStepNo(3);
      } else if (stepNo === 6) {
        setStepNo(4);
      } else {
        setStepNo(stepNo - 1);
      }
    }
  };

  const handleNext = () => {
    handleSubmit((data) => {
      // setReBook(false);
      // setsignin(false);
      if (stepNo === 3 && access_token) {
        setStepNo(7);
      } else {
        setStepNo(stepNo + 1);
      }
    })();
  };

  const [reBook, setReBook] = useState(false);

  const [signin, setsignin] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    "startDate"
  );

  const handleTimeChange = (selectedTime: any) => {
    setValue(apiConstants.time, selectedTime);
  };

  const [totalAmountArr, setTotalAmountArr] = useState<any>([]);
  const [uniqueCode, setUniqueCode] = useState<any>();

  const [stylistServicesData, setStylistServicesData] = useState<any | null>(
    null
  );

  async function getResponse() {
    let stylist_unique_code = {
      stylist_unique_code: getURLstylistCode(),
      is_active: true,
      is_deleted: false,
      limit: 100,
      offset: 0,
      is_available_for_online_booking: true,
    };

    if (getValues(apiConstants.location) === "mbl_booking") {
      stylist_unique_code["is_mobile_booking_location_required"] = true;
    }

    try {
      const apiResponse = await makeGetCall({
        url: endpoints.stylist_services,
        params: stylist_unique_code,
      });
      const data = await apiResponse;

      setStylistServicesData(data);
      // setValue(apiConstants.location, data?.results[0].id);
      // setIsCheckedId(data?.results[0]?.id);
    } catch (err) {}
  }

  const [prevPage, setPrevPage] = useState<number | boolean>(false);

  const access_token = getLocalStorage("access_token");

  return (
    <div className="booking-wrapper">
      {stepNo === 1 && (
        <Location
          control={control}
          watch={watch}
          errors={errors}
          handleNext={handleNext}
          setValue={setValue}
          getValues={getValues}
          setTotalAmountArr={setTotalAmountArr}
        />
      )}

      {stepNo === 2 && (
        <Features
          control={control}
          watch={watch}
          errors={errors}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          setValue={setValue}
          getValues={getValues}
          setsignin={setsignin}
          setStepNo={setStepNo}
          totalAmountArr={totalAmountArr}
          setTotalAmountArr={setTotalAmountArr}
          stylistServicesData={stylistServicesData}
          setStylistServicesData={setStylistServicesData}
          setPrevPage={setPrevPage}
          getResponse={getResponse}
        />
      )}
      {stepNo === 3 && (
        <Schedule
          control={control}
          errors={errors}
          handleNext={handleNext}
          getValues={getValues}
          handlePrevious={handlePrevious}
          setValue={setValue}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
          handleTimeChange={handleTimeChange}
          setStepNo={setStepNo}
        />
      )}

      {stepNo === 4 && (
        <SignUp
          control={control}
          watch={watch}
          errors={errors}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          isValid={isValid}
          setValue={setValue}
          getValues={getValues}
        />
      )}
      {stepNo === 5 && (
        <Otp
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          getValues={getValues}
          setValue={setValue}
          setStepNo={setStepNo}
        />
      )}

      {stepNo === 6 && (
        <SignUpForm
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          getValues={getValues}
          setValue={setValue}
          control={control}
          watch={watch}
          errors={errors}
          isValid={isValid}
        />
      )}

      {stepNo === 7 && (
        // <PaymentTest />
        <Payment
          control={control}
          watch={watch}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          setValue={setValue}
          getValues={getValues}
          totalAmountArr={totalAmountArr}
        />
      )}

      {stepNo === 8 && <Success setStepNo={setStepNo} setValue={setValue} />}
      {/* <Cancellation /> */}

      {stepNo === 9 && <Rescheduled />}

      {/* <Navigation /> */}

      {/* <Bookings /> */}

      {/* <CancelPopup /> */}

      {/* <MyProfile /> */}
    </div>
  );
}
