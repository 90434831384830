import { apiConstants } from "./constants/apiConstants";

//form data for  add consultation
export const locationSelectorForm = {
  [apiConstants.location]: "",
  [apiConstants.calander]: "",
  [apiConstants.time]: "",
  [apiConstants.mobile_number]: "",
  [apiConstants.timezone]: "",
};
