import React from "react";

import { emptyFunction } from "../../utils/globalFunctions";
// import Loader from "./Loader";
interface ButtonProps {
    onClick?: () => void;
    className?: string;
    btnTxt: string;
    type?: any;
    restProps?: any;
    isLoading?: boolean;
    disabled?: boolean;
    loaderColor?: string;
    noImg?: boolean;
    style?: React.CSSProperties;
}

const Button = (props: ButtonProps) => {
    const {
        onClick = emptyFunction,
        className,
        btnTxt = "",
        isLoading = false,
        disabled,
        type = "",
        loaderColor,
        noImg,
        ...restProps
    } = props;

    return (
        <button
            style={props?.style}
            type={type}
            disabled={disabled}
            onClick={() => {
                if (!isLoading) onClick();
            }}
            className={`common_btn ${className ? className : ""} ${disabled ? "disabled" : ""
                }`}
            {...restProps}
        >
            {isLoading ? (
                // <Loader />
                // Use this for loader state
                ""
            ) : (
                <>
                    {btnTxt}{" "}
                    {/* {!noImg && <img src={`assets/img/icons/arrowright.svg`} />} */}
                </>
            )}
        </button>
    );
};

export default Button;
