import React, { useEffect, useState } from "react";

import AllRoutes from "./routers/routes";
import "react-toastify/dist/ReactToastify.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import useStore from "./zustandstore/index";

const App = () => {
  const dynamicTitle = "Unwritten Hair";
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{dynamicTitle}</title>
        </Helmet>
        <AllRoutes />
      </HelmetProvider>
    </>
  );
};

export default App;
