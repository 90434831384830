import * as yup from "yup";
import { apiConstants } from "../../utils/constants/apiConstants";

export const locationValidation = yup.object({
  [apiConstants.location]: yup.string().required("Please select a location"),
});

export const featuresValidation = yup.object({
  [apiConstants.services]: yup.string().required("Please select a feature"),
});

export const calanderValidation = yup.object({
  [apiConstants.calander]: yup
    .string()
    .required("Please select date")
    .nullable(),
  [apiConstants.time]: yup.string().required("Please select time"),
});

export const noValidation = yup.object({});

export const phoneValidation = yup.object({
  [apiConstants.mobile_number]: yup
    .string()
    .required("Please enter the mobile number"),
});

export const otpValidation = yup.object({
  [apiConstants.otp_number]: yup
    .string()
    .required("Please enter the mobile number"),
});

export const signupValidation = yup.object({
  [apiConstants.first_name]: yup
    .string()
    .required("Please enter the first name"),
  // [apiConstants.last_name]: yup.string().required("Please select last name"),
  [apiConstants.email]: yup.string().required("Please select email"),
  [apiConstants.terms_check]: yup
    .boolean()
    .required("Please select checkbox")
    .oneOf([true]),
});

export const myProfileValidation = yup.object({
  [apiConstants.first_name]: yup
    .string()
    .required("Please enter the first name"),

  [apiConstants.email]: yup.string().required("Please select email"),

  [apiConstants.mobile_number]: yup
    .string()
    .required("Please enter the mobile number"),
});
