import React from "react";
import { Link } from "react-router-dom";
import useStore from "../../zustandstore";
import { getURLstylistCode } from "../../utils/globalFunctions";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { removeLocalStorage } from "../../utils";
export default function Navigation() {
  // const setShowNav = useStore((state: any) => state.setShowNav);

  const { setShowNav, setReschedule } = useStore((state: any) => state);
  const hideNav = () => {
    setShowNav(false);
    setReschedule(false);
    removeLocalStorage("is_registered_online_consumer1");
  };

  const removeStorage = () => {
    removeLocalStorage("access_token");
    setShowNav(false);
    removeLocalStorage("is_registered_online_consumer1");
    removeLocalStorage("is_registered_online_consumer");
    setReschedule(false);
  };

  return (
    <div className="navigation">
      <div className="sign_up_screen ">
        <div className="upper-wrapper">
          <ul>
            <li onClick={hideNav}>
              <Link to={`/${getURLstylistCode()}/my_profile`}>
                <div>
                  <img src={images?.my_prfl} alt="" style={{ width: "17px" }} />
                  My Profile & Settings{" "}
                </div>
                <img src={images.right_arrow_icon} alt="" />
              </Link>
            </li>
            <li onClick={hideNav}>
              <Link to={`/${getURLstylistCode()}/payment_modes`}>
                <div>
                  <img
                    src={images?.my_cards}
                    alt=""
                    className="new-booking-icon"
                  />
                  Payment Modes{" "}
                </div>
                <img src={images.right_arrow_icon} alt="" />
              </Link>
            </li>
            <li onClick={hideNav}>
              <Link to={`/${getURLstylistCode()}`}>
                <div>
                  {" "}
                  <img
                    src={images.new_booking}
                    alt=""
                    className="new-booking-icon"
                  />
                  {STRING_CONSTANTS.new_booking}{" "}
                </div>
                <img src={images.right_arrow_icon} alt="" />
              </Link>
            </li>
            <li onClick={hideNav}>
              <Link to={`${getURLstylistCode()}/bookings`}>
                <div>
                  {" "}
                  <img
                    src={images?.my_booking}
                    alt=""
                    style={{ width: "17px" }}
                  />
                  My Bookings{" "}
                </div>
                <img src={images.right_arrow_icon} alt="" />
              </Link>
            </li>

            <li onClick={hideNav}>
              <Link to={`/${getURLstylistCode()}/transaction_history`}>
                <div>
                  {" "}
                  <img
                    src={images?.history}
                    alt=""
                    className="new-booking-icon"
                  />
                  Transaction History{" "}
                </div>
                <img src={images.right_arrow_icon} alt="" />
              </Link>
            </li>
            <li>
              <a
                href="https://dev.unwritten.hair/policies/terms-of-service"
                target="_blank"
              >
                <div>
                  {" "}
                  <img
                    src={images?.terms_icon}
                    alt=""
                    style={{ width: "17px" }}
                  />
                  Terms & Conditions{" "}
                </div>
                <img src={images.right_arrow_icon} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://dev.unwritten.hair/policies/privacy-policy"
                target="_blank"
              >
                <div>
                  {" "}
                  <img
                    src={images?.privacy_icon}
                    alt=""
                    className="new-booking-icon"
                  />
                  {STRING_CONSTANTS.privacy_heading}{" "}
                </div>
                <img src={images.right_arrow_icon} alt="" />
              </a>
            </li>

            <li onClick={removeStorage}>
              <Link to={`/${getURLstylistCode()}`}>
                <div>
                  <img
                    src={images?.sign_out}
                    alt=""
                    className="new-booking-icon"
                  />
                  Sign Out
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
