import Cookies from "js-cookie";
import { toast, TypeOptions } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { CookiesKeysType, DateTypes, TimeTypes } from "../types";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";



const storage =
	// process.env.REACT_APP_PRODUCTION === "true" ? secureLocalStorage : localStorage;
	localStorage;


export const emptyFunction = () => { };

export const generateFormData = (obj: any) => {
	let formData = new FormData();
	for (let [key, value] of Object.entries(obj)) {
		if (Array.isArray(value)) {
			formData.append(`${key}`, `${value}`);
		} else {
			formData.append(`${key}`, `${value}`);
		}
	}
	return formData;
};

export const setCookie = (key: CookiesKeysType, value: string | any) =>
	Cookies.set(key, value);

export const getCookie = (key: CookiesKeysType) => Cookies.get(key);

export const removeCookie = (key: CookiesKeysType) => Cookies.remove(key);

export const setSessionStorage = (key: string, value: string | any) =>
	window.sessionStorage.setItem(key, value);
export const getSessionStorage = (key: string) => sessionStorage.getItem(key);
export const removeSessionStorage = (key: string) => sessionStorage.removeItem(key);

// adding deleting and updating local storage
export const getLocalStorage = (key: string) => {
	const storedData = storage.getItem(key);
	return storedData ? JSON.parse(storedData) : null
};

export const setLocalStorage = (key: string, value: any) => storage.setItem(key, JSON.stringify(value));;





export const removeLocalStorage = (key: string) => storage.removeItem(key);


export const isUserAuthenticated = () => getLocalStorage("access_token");


export const resetAllCookie = () => {
	Cookies.remove("access_token");
	Cookies.remove("refresh_token");
	Cookies.remove("user");
};

export const showToast = (
	message: string | any,
	type: TypeOptions,
	toastId: any = "my_toast"

) => {



	if (!toast.isActive(toastId)) {
		toast(message, {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: false,
			type,
			// theme:"colored",
			toastId: toastId || undefined,
		});
	}
};


export const convertB64DataToByteArray = (b64Data: any) => {
	const encodedData = window.btoa(b64Data); // encode a string
	const byteCharacters = window.atob(encodedData);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	return byteArray;
};

export const createGuid = () => uuidv4();

export const userFullName = (
	firstName: string,
	midName?: string,
	lastName?: string
) => {
	const name = `${firstName ? firstName : ""} ${midName ? midName : ""} ${lastName ? lastName : ""
		}`;
	return name;
};

// export const formatDateTime = (
// 	timeStamp: Date | any,
// 	formatTo?: DateTypes | TimeTypes
// ) => {
// 	return timeStamp
// 		? moment(timeStamp).format(formatTo)
// 		: moment().format(formatTo);
// };

export const blobToFile = (theBlob: Blob, name = "filename.png"): File => {
	return new File([theBlob], name, {
		lastModified: new Date().getTime(),
		type: theBlob.type,
	});
};

export const dataURLtoFile = (dataurl: any, filename: string) => {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
};
export const goToNextInput = (reference: any, context: any) => {
	return context[reference] ? context[reference].current.focus() : null;
};

export const dateFormatter = (inputDate?: any, format: string = "YYYY-MM-DD") => {
	return moment(inputDate ? inputDate : null).format(format);
};


export const positiveNumber = new RegExp("^([1-9]|10)$");
export const onlyNumber = new RegExp(/^[0-9]*$/);