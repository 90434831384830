import React, { useState } from "react";
import { makePostCall } from "../../effects/requests";
import Button from "../../global/ui/Button";
import InputField from "../../global/ui/InputField";
import { setLocalStorage, showToast } from "../../utils";
import { apiConstants } from "../../utils/constants/apiConstants";
import endpoints from "../../utils/endpoints";
import { getURLstylistCode } from "../../utils/globalFunctions";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
interface SignUpFormProps {
  handleNext: () => void;
  control?: any;
  errors?: any;
  watch?: any;
  setValue?: any;
  getValues?: any;
  handlePrevious: () => void;
  isValid?: any;
  // setIsMobileValid: any;
  // isMobileValid: any;
}

export default function SignUpForm(props: SignUpFormProps) {
  const {
    control,
    errors,
    watch,
    handleNext,
    handlePrevious,
    isValid,
    setValue,
    getValues,
  } = props;

  const num = `${getValues(apiConstants.country_code)}${getValues(
    apiConstants.mobile_number
  )}`;

  const mobileNumber = parseInt(num.toString().replace(/\s/g, ""));

  async function sendreginfo() {
    let apiPayload = {
      email: getValues(apiConstants.email),
      user_type: "C",
      mobile_number: "+" + mobileNumber,
      first_name: getValues(apiConstants.first_name),
      last_name: getValues(apiConstants.last_name),
    };

    try {
      const apiResponse = await makePostCall({
        url: endpoints.register,
        apiPayload,
      });

      const resp = await apiResponse;

      if (resp.status.code === 200) {
        handleNext();
      } else {
        showToast(resp.status.detail.email[0], "error");
        if (!resp.status.detail.email[0]) {
          showToast(resp.status.detail.email[0], "error");
        }
      }

      if (resp?.token?.access) {
        setLocalStorage("access_token", apiResponse?.token?.access);
      }
    } catch (err) {
      // showToast("fnn", "error");
    }
  }

  const sendsignupdata = () => {
    sendreginfo();
  };

  return (
    <div className="step_5 sign_up_screen otp_screen">
      <div className="upper-wrapper">
        <div className="back-logo">
          <div className="go-back" onClick={handlePrevious}>
            <img src={images.go_back} alt="" />
          </div>
        </div>
        <div className="Name form-field">
          <label htmlFor="firstname">First Name</label>
          <InputField
            name={apiConstants.first_name}
            value={getValues(apiConstants.first_name)}
            type="text"
            control={control}
          />
        </div>
        <div className="Name form-field">
          <label htmlFor="lastname">Last Name</label>

          <InputField
            name={apiConstants.last_name}
            type="text"
            control={control}
            id="lastname"
          />
        </div>

        <div className="email form-field">
          <label htmlFor="emailid">Email Address</label>
          <InputField
            name={apiConstants.email}
            type="email"
            control={control}
            id="emailid"
          />
        </div>

        <div className="terms_agree custom_checkbox">
          <InputField
            name={apiConstants.terms_check}
            type="checkbox"
            control={control}
            id="terms"
          />

          <span className="checkmark"></span>
          <label htmlFor="terms">
            {STRING_CONSTANTS.signup_form}{" "}
            <a
              href="https://dev.unwritten.hair/policies/terms-of-service"
              target="_blank"
            >
              {STRING_CONSTANTS.terms}
            </a>{" "}
            &{" "}
            <a
              href="https://dev.unwritten.hair/policies/privacy-policy"
              target="_blank"
            >
              {STRING_CONSTANTS.privacy}
            </a>
            .
          </label>
        </div>

        <div className="send_email custom_checkbox">
          <InputField
            name=""
            type="checkbox"
            control={control}
            id="reminders"
          />
          <span className="checkmark"></span>
          <label htmlFor="reminders">Send me email reminders & offers</label>
        </div>
      </div>
      <div className="bottom-wrapper">
        <Button
          className="continue-btn"
          type="submit"
          disabled={!isValid}
          btnTxt={"Sign Up"}
          isLoading={false}
          onClick={sendsignupdata}
        />
      </div>
    </div>
  );
}
