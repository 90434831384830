import React, { forwardRef, useState } from "react";
import { Controller, useController } from "react-hook-form";
import moment from "moment";
interface InputFieldProps {
  name: string;
  type: React.HTMLInputTypeAttribute;
  label?: string;
  inputLabel?: string;
  as?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  hideErrorMessage?: boolean;
  errors?: object | any;
  value?: string | boolean | any;
  maxLength?: number | any;
  id?: string;
  extramsg?: string;
  control?: any;
  isChecked?: boolean;
  customOnChange?: any;
  reference?: any;
}

const InputField = forwardRef((props: InputFieldProps, ref: any) => {
  const {
    name,
    type,
    label,
    inputLabel,
    placeholder,
    style,
    control,
    className,
    extramsg,
    errors,
    id,
    customOnChange,
    reference,
  } = props;

  const [isFocus, setIsFocus] = useState(false);
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  const currentDate = moment().format("YYYY-MM-DD");

  const handleOnchange = (e: any) => {
    if (customOnChange) {
      customOnChange(e.target);
      onChange(e);
    } else {
      onChange(e);
    }
  };
  const renderMainInput = () => {
    switch (type) {
      case "radio":
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  type={type}
                  onChange={(e) => customOnChange(e)} // customOnChange
                  id={value}
                  checked={value === id}
                  placeholder={placeholder}
                  value={id}
                />
              );
            }}
          />
        );
      case "textarea":
        return (
          <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => {
              return (
                <textarea
                  onChange={onChange}
                  placeholder={placeholder}
                  value={value}
                />
              );
            }}
          />
        );

      case "checkbox":
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  name={name}
                  type="checkbox"
                  onChange={(e) => {
                    handleOnchange(e);
                  }}
                  id={id}
                  value={id}
                  defaultChecked={value === id}
                />
              );
            }}
          />
        );

      case "number":
        return (
          <input
            onChange={(e) => handleOnchange(e)}
            type={type}
            ref={reference}
            placeholder={placeholder}
            value={value || ""}
            min={1}
          />
        );
      default:
        return (
          <Controller
            control={control}
            name={name}
            render={() => {
              return (
                <input
                  onChange={(e) => handleOnchange(e)}
                  type={type}
                  ref={reference}
                  placeholder={placeholder}
                  value={value || ""}
                  max={type === "date" ? currentDate : ""}
                />
              );
            }}
          />
        );
    }
  };

  return (
    <>
      {(inputLabel || label || extramsg) && (
        <div style={{ flex: 1 }}>
          {inputLabel && <span>{inputLabel}</span>}
          {label && <label htmlFor={props?.id}>{label}</label>}
          {extramsg && <label className="extra_msg">{extramsg}</label>}
        </div>
      )}

      {renderMainInput()}
      {errors && errors[name] && (
        <span className="text-danger">{errors[name]?.message}</span>
      )}
    </>
  );
});

export default InputField;
