import { makeGetCall } from "../effects/requests";
import { apiConstants } from "../utils/constants/apiConstants";
import endpoints from "../utils/endpoints";
import {getURLstylistCode} from "../utils/globalFunctions";
export const getAvailableTiming = (
{
	selected_dt,
	tz ,
  location_id,
  booking_duration,
  booking_to_update,
	
}: {
	selected_dt: any;
	tz: any;
	location_id?: any;
  booking_duration:any;
  booking_to_update?:any;
	
}
) => {
  const availableTimingParams = {
    tz: tz,
    stylist_unique_code: getURLstylistCode(),
    selected_dt: `${selected_dt}`,
    location_id: location_id,
    booking_duration: booking_duration, //NEED TO UPDATE THIS VALUE BASED ON SUM OF SELECTED SERVICES
    booking_to_update :booking_to_update,
  };
  return makeGetCall({
    url: endpoints.available_timing,
    params: availableTimingParams,
  });
};
