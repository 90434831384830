import React, { useEffect, useState } from "react";
import { makeGetCall } from "../../effects/requests";
import Button from "../../global/ui/Button";
import endpoints from "../../utils/endpoints";
import {
  convertLocal,
  getURLstylistCode,
  getURLwithstylistCode,
} from "../../utils/globalFunctions";
import useStore from "../../zustandstore";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

export default function Cancelled() {
  const { bookingAPIData, mainstylistLocationData } = useStore(
    (state: any) => state
  );
  //   const mainstylistLocationData = useStore(
  //     (state: any) => state.stylistLocationData
  //   );

  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" +
        bookingAPIData?.location[0] +
        "," +
        bookingAPIData?.location[1],
      "_blank"
    );
  };

  const navigate = useNavigate();

  const [mainstylistLocationDataa, setMainstylistLocationDataa] =
    useState<any>();

  async function getResponse() {
    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.stylist_location}?${getURLwithstylistCode()}`,
      });

      const data = await apiResponse;
      setMainstylistLocationDataa(data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getResponse();
  }, []);

  const setShowNav = useStore((state: any) => state.setShowNav);

  const navOpen = () => {
    setShowNav(true);
  };
  return (
    <div className="sign_up_screen thankyou cancelled-booking">
      <div className="upper-wrapper">
        <div className="back-logo">
          <div className="go-back">
            <div className="user-icon" onClick={navOpen}>
              <img src={images.user_1} alt="" />
            </div>
          </div>
        </div>
        <div className="thankyou-section">
          <div className="thankyou-inner">
            <div className="thanks-top">
              <div className="msg-box">
                <img src={images.cancel_icon} alt="" />
                <span>Appointment booking</span>
                <b>Cancelled</b>
              </div>
              <div className="time-outer">
                <div className="date-time">
                  <div className="date">
                    <img src={images.date_icon} alt="" />
                    {convertLocal(bookingAPIData?.start_dt, "Do MMM YYYY")}
                  </div>
                  <div className="time">
                    <img src={images.time_icon} alt="" />
                    {convertLocal(bookingAPIData?.start_dt, "hh:mm A")}-{" "}
                    {convertLocal(bookingAPIData?.end_dt, "hh:mm A")}
                  </div>
                </div>
                <div className="address-map">
                  <div className="adrs">
                    <img src={images.address_icon} alt="" />
                    {bookingAPIData?.address}
                  </div>
                  <div className="map" onClick={() => showInMapClicked()}>
                    <img src={images.thanks_icon} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="thanks-bottom">
              {STRING_CONSTANTS.booking_no} {bookingAPIData?.booking_id}
            </div>
          </div>
          <div className="service-summery">
            <span>{STRING_CONSTANTS.service_summary}</span>

            <ul>
              {bookingAPIData?.services?.map((item) => {
                return (
                  <li key={item.service_id}>
                    <p style={{ textAlign: "left" }}>
                      <span>
                        {item.name || item.service__name}
                        {item.duration || item.service__duration
                          ? ","
                          : ""}{" "}
                        {item.duration || item.service__duration}{" "}
                        {item.duration || item.service__duration
                          ? STRING_CONSTANTS.minutes
                          : ""}
                      </span>

                      {item.total_quantity && (
                        <>
                          <br />
                          <span className="qty">
                            <img src={images.stock_icon} alt="" />{" "}
                            {STRING_CONSTANTS.total_qty}
                            {item.total_quantity}
                          </span>
                          <br />
                        </>
                      )}
                    </p>

                    <b>
                      $
                      {item.total_quantity
                        ? item.total_quantity * item.price
                        : item.price}{" "}
                    </b>
                  </li>
                );
              })}
            </ul>
          </div>

          {bookingAPIData?.products?.length > 0 && (
            <div className="service-summery">
              <span>{STRING_CONSTANTS.product_heading}</span>

              <ul>
                {bookingAPIData?.products?.map((item) => {
                  return (
                    <li key={item.product_id}>
                      <p style={{ textAlign: "left" }}>
                        <span>{item.product || item.product__name}</span>

                        {item.stock_quantity > 0 && (
                          <>
                            <br />
                            <span className="qty">
                              <img src={images.stock_icon} alt="" />{" "}
                              {STRING_CONSTANTS.stock_qty}
                              {item.stock_quantity}
                            </span>
                          </>
                        )}

                        {item.warehouse_quantity > 0 && (
                          <>
                            <br />
                            <span className="qty warehouse">
                              <img src={images.warehouse_icon} alt="" />{" "}
                              {STRING_CONSTANTS.warehouse_quantity}
                              {item.warehouse_quantity}
                            </span>
                          </>
                        )}
                      </p>
                      <b>${item.total_quantity * item.price}</b>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div className="service-summery cancel-info need-assist">
            <span>
              <img src={images.need_assist_icon} alt="" />
              {STRING_CONSTANTS.need_assist}
            </span>
            <ul>
              <li>
                <span>
                  {STRING_CONSTANTS.contact_us_via}{" "}
                  <a
                    href={`mailto:${mainstylistLocationDataa?.stylist_info?.email}`}
                  >
                    {mainstylistLocationDataa?.stylist_info?.email}
                  </a>{" "}
                  {STRING_CONSTANTS.or_over_phone}{" "}
                  <a
                    href={`tel:${mainstylistLocationDataa?.stylist_info?.mobile_number}`}
                  >
                    {mainstylistLocationDataa?.stylist_info?.mobile_number}
                  </a>
                  .
                </span>
              </li>
            </ul>
          </div>
          <div className="service-summery need-assist follow-social">
            {mainstylistLocationDataa?.stylist_info?.instagram_handle ||
            mainstylistLocationDataa?.stylist_info?.facebook_handle ||
            mainstylistLocationDataa?.stylist_info?.tiktok_handle ? (
              <span>
                Follow {mainstylistLocationDataa?.stylist_info?.full_name}{" "}
                socials
              </span>
            ) : null}

            <ul className="social-links">
              {mainstylistLocationDataa?.stylist_info?.instagram_handle && (
                <li>
                  <a
                    href={
                      mainstylistLocationDataa?.stylist_info?.instagram_handle
                    }
                    target="_blank"
                  >
                    <img src={images.insta_icon} alt="" />
                  </a>
                </li>
              )}
              {mainstylistLocationDataa?.stylist_info?.facebook_handle && (
                <li>
                  <a
                    href={
                      mainstylistLocationDataa?.stylist_info?.facebook_handle
                    }
                    target="_blank"
                  >
                    <img src={images.fb_icon} alt="" />
                  </a>
                </li>
              )}

              {mainstylistLocationDataa?.stylist_info?.tiktok_handle && (
                <li>
                  <a
                    href={mainstylistLocationDataa?.stylist_info?.tiktok_handle}
                    target="_blank"
                  >
                    <img src={images.tiktok_icon} alt="" />
                  </a>
                </li>
              )}

              {/* <li>
                <a
                  href={mainstylistLocationDataa?.stylist_info?.linkedln_handle}
                  target="_blank"
                >
                  <img src={images.linkedin_icon} alt="" />
                </a>
              </li> */}
            </ul>
          </div>
          <div className="buttons">
            <Link
              to={`/${getURLstylistCode()}/rebook/?id=${bookingAPIData.id}`}
              className="continue-btn re-sch"
            >
              {STRING_CONSTANTS.booking_rebook}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
