import axios, { AxiosError, AxiosResponse } from "axios";
import { getCookie, getLocalStorage, isUserAuthenticated, removeLocalStorage, resetAllCookie, showToast } from ".";
import { makePostCall } from "../effects/requests";
// import { errorMessage } from "./constants";
import endpoints from "./endpoints";

// const _instance: any = axios.create({
//     baseURL: endpoints.API_BASE_URL,
//     timeout: 60000,
// });

//this method allow to update api base url
const updateBaseUrl = (protocol?: any) => {
  let baseURL = protocol || endpoints.API_BASE_URL;
  return baseURL;
};
const instance: any = axios.create({
  baseURL: updateBaseUrl(),
  timeout: 60000,
});

instance.interceptors.request.use(
  async function (config: any) {
    const accessToken: any = await getLocalStorage("access_token");
    const contentType: string = "application/json";
    // config.headers['Mobile-Number'] = "0403232748";
    // config.headers["Device-Id"] = "AppBoxer";

    config.port = 443;
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      config.headers["Content-Type"] = contentType;
    }



    const element: any = document.getElementById("loader");
    // element.style.display = "flex";
    // element.style.display = "none";
    // Do something before request is sent

    //Updating api base url
    // config.baseURL = updateBaseUrl(
    //     window.location.protocol
    // );

    

    return config;
  },
  function (error: AxiosError) {
    const element: any = document.getElementById("loader");
    // element.style.display = "none";

    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    

    const element: any = document.getElementById("loader");
    // element.style.display = "none";

    const data: any = response?.data;
    const statusCode: any = response?.data?.status?.code;
    const detail = data?.status?.detail;


    if (statusCode === 401 && isUserAuthenticated()) {

      removeLocalStorage("access_token");
	  window.location.reload();
      resetAllCookie();
    }
    // else if (statusCode === 403 || statusCode === 400)
    else if (statusCode === 403) {
      if (detail) {
        for (var key in detail) {
          if (detail.hasOwnProperty(key)) {
            // if (!detail[0]) {
            //   showToast(detail[key][0], "error", detail[key][0]);
            // } else {
            //   showToast(detail[0], "error", detail[0]);
            // }
          }
        }
      } else {
        const message = data?.status?.message;
        // showToast(message, "error", message);
      }
    }
    return data;
  },
  async function (error: AxiosError) {
    const element: any = document.getElementById("loader");
    // showToast(error.message, "error");
    // element.style.display = "none";
    const response: any = error?.response;
    if (response) {
      const errorData = response?.data?.errors;
      const status: any = response?.status?.code;

      
      if (status === 401 && isUserAuthenticated()) {
        removeLocalStorage("access_token");
	  window.location.reload();
        resetAllCookie();
      }
      // else if (status === 400) {
      // 	if (errorData) {
      // 		for (const key in errorData) {
      // 			if (errorData.hasOwnProperty(key)) {
      // 				if (!errorData[0]) {
      // 					showToast(errorData[key][0], "error", errorData[key][0]);
      // 				} else {
      // 					showToast(errorData[0], "error", errorData[0]);
      // 				}
      // 			}
      // 		}
      // 	}
      // }
      // else if (status >= 500) {
      // 	showToast(
      // 		errorMessage.server_error,
      // 		"error",
      // 		errorMessage.server_error
      // 	);
      // }
    }
    return Promise.reject(error);
  }
);

export { instance };
