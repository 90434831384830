import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeGetCall } from "../../effects/requests";
import Button from "../../global/ui/Button";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import endpoints from "../../utils/endpoints";
import { getURLwithstylistCode } from "../../utils/globalFunctions";
import useStore from "../../zustandstore";

export default function Privacy() {
  const navigate = useNavigate();

  const { setStylistUniqueCode } = useStore((state: any) => state);

  const [termsApi, setTermsApi] = useState<any>();
  async function getResponse() {
    const urlParams = new URLSearchParams(window.location.search);
    let uc = urlParams.get("stylist_unique_code");

    setStylistUniqueCode(urlParams.get("stylist_unique_code"));
    const stylist_unique_code = { stylist_unique_code: uc };

    try {
      const apiResponse = await makeGetCall({
        // url: endpoints.privacy_policy ,
        url: `${endpoints.privacy_policy}?${getURLwithstylistCode()}`,
        // params: stylist_unique_code,
      });

      const data = await apiResponse;
      setTermsApi(data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getResponse();
  }, []);

  const setShowNav = useStore((state: any) => state.setShowNav);

  const navOpen = () => {
    setShowNav(true);
  };

  return (
    <div className="booking-wrapper terms">
      <div className="upper-wrapper">
        {window.location.href.includes("signup") ? (
          ""
        ) : (
          <div className="go-back" onClick={navOpen}>
            <img src={images.go_back} alt="" />
          </div>
        )}

        <h1>{STRING_CONSTANTS.privacy_heading}</h1>
        {/* <a href={termsApi?.data?.consumer_document} target="_blank">
          Click here to Load/Download consumer Pdf
        </a> */}
        <div className="pdf">
          <iframe
            src={`${termsApi?.data?.consumer_document}#toolbar=0`}
            style={{
              width: "100%",
              height: "500px",
              border: "0px",
              paddingBottom: "50px",
            }}
          ></iframe>
        </div>
        <Button
          className="continue-btn"
          type="submit"
          btnTxt={"Done"}
          isLoading={false}
          onClick={navOpen}
        />
      </div>
    </div>
  );
}
