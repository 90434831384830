import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeGetCall } from "../../effects/requests";
import { images } from "../../utils/constants/assets";
import endpoints from "../../utils/endpoints";
import { getURLwithstylistCode } from "../../utils/globalFunctions";
import useStore from "../../zustandstore";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import Button from "../../global/ui/Button";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

export default function Terms() {
  const navigate = useNavigate();

  const { setStylistUniqueCode } = useStore((state: any) => state);

  const [termsApi, setTermsApi] = useState<any>();
  async function getResponse() {
    const urlParams = new URLSearchParams(window.location.search);
    let uc = urlParams.get("stylist_unique_code");

    setStylistUniqueCode(urlParams.get("stylist_unique_code"));
    const stylist_unique_code = { stylist_unique_code: uc };

    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.terms}?${getURLwithstylistCode()}`,
        // params: stylist_unique_code,
      });

      const data = await apiResponse;
      setTermsApi(data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getResponse();
  }, []);

  const setShowNav = useStore((state: any) => state.setShowNav);

  const navOpen = () => {
    setShowNav(true);
  };
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="booking-wrapper terms">
      <div className="upper-wrapper">
        {window.location.href.includes("signup") ? (
          ""
        ) : (
          <div className="go-back" onClick={navOpen}>
            <img src={images.go_back} alt="" />
          </div>
        )}

        <h1>{STRING_CONSTANTS.terms_heading}</h1>
        {/* <a href={termsApi?.data?.consumer_document} target="_blank">
          Click here to Load/Download consumer Pdf
        </a> */}

        {/* <Document
          file={{
            url: "http://example.com/sample.pdf",
            httpHeaders: { "X-CustomHeader": "40359820958024350238508234" },
            withCredentials: true,
          }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p> */}

        <div className="pdf">
          <iframe
            src={`${termsApi?.data?.consumer_document}#toolbar=0`}
            style={{
              width: "100%",
              height: "500px",
              border: "0px",
              paddingBottom: "50px",
            }}
          ></iframe>
        </div>

        {/* <object
          width="100%"
          height="400"
          data={termsApi?.data?.consumer_document}
          type="application/pdf"
        ></object> */}

        <Button
          className="continue-btn"
          type="submit"
          btnTxt={"Done"}
          isLoading={false}
          onClick={navOpen}
        />
      </div>
    </div>
  );
}
