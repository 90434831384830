import React from "react";
import { images } from "../../utils/constants/assets";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import useStore from "../../zustandstore";

export default function NoBookingFound() {
  const setShowNav = useStore((state: any) => state.setShowNav);
  const navOpen = () => {
    setShowNav(true);
  };
  return (
    <div className="sign_up_screen thankyou cancelled-booking">
      <div className="upper-wrapper">
        <div className="back-logo">
          <div className="go-back">
            <div className="user-icon" onClick={navOpen}>
              <img src={images.user_1} alt="" />
            </div>
          </div>
        </div>
        <p style={{ color: "red" }}>{STRING_CONSTANTS.no_booking_found}</p>
      </div>
    </div>
  );
}
