export const ROUTES_CONSTANTS = {
    index: "/",
    signin: "/signin",
    signup: "/signup",
    forgotpwd: "/forgotPassword",
    resetpwd: "/resetPassword",
    terms: "/terms",
    privacy: "/privacy",
    dashboard: "/dashboard",
    test: "/test",
 bookings:"/bookings" ,
 booking_details:"/booking_details"   ,
 reschedule: "/reschedule",
 rescheduleSucess:"/rescheduleSucess",   
 my_profile:"/my_profile"   ,
 re_schedule:"/re_schedule" ,
 payment_modes:"/payment_modes"   ,
 transaction_history:  "/transaction_history",
 
 rebook: "/rebook",
    rebookpayment:"/rebookpayment",
    rebookSuccess: "/rebookSuccess",

    confirmed: "/Confirmed",

    no_route:"*"
}