

import moment from 'moment-timezone';
export const isNotEmpty = (data: any) => {
    return data !== null && data !== undefined && data !== "" && (typeof data === "object" && Object.keys(data).length > 1);
};

export const isNotEmptyString = (data: any) => {
    return data !== null && data !== undefined && data !== "" ;
};

export const objToFormData = (rawData: any) => {
    let formData: FormData = new FormData();
    if (rawData && rawData != null && typeof rawData === "object") {
        Object.keys(rawData).map((item, index) => {
            formData.append(item, rawData[item]);
        });
    }
    return formData;
};



export const emptyFunction = () => { }

export const isDataRefreshing = (typeOfRequest: number) => {
    return typeOfRequest === FLATLIST_REQUEST.REFRESH;
};

export const isDataPaginating = (typeOfRequest: number) => {
    return typeOfRequest === FLATLIST_REQUEST.PAGINATION;
};

export const shouldShowTopLoaderOnListing = (typeOfRequest: number) => {
    return typeOfRequest === FLATLIST_REQUEST.FIRST_TIME;
};

export enum FLATLIST_REQUEST {
    FIRST_TIME,
    REFRESH,
    PAGINATION
}


export const convertDate = (date: any, format: any) => {
	return moment.utc(date).format(format);
}

export const convertDateToUTC = (date: string) => {
	let newDate = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
	return newDate;
}



export const getCurrentTimeZone = moment.tz.guess();



export const convertToTimeZone = (utcTime, timeZone) => {
  return moment.utc(utcTime).tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
};

export const convertToTimeZoneDate = (utcTime, timeZone,format) => {
  return moment.utc(utcTime).tz(timeZone).format(format);
};

export const convertToTimeZoneTime = (utcTime, timeZone,format) => {
  return moment.utc(utcTime).tz(timeZone).format(format);
};




export const convertLocal = (date: any, format: any) => {
	return moment.utc(date).local().format(format);
}




export const compareJSON = (obj1: object, obj2: object) => {
	const result = {};
	if (Object.is(obj1, obj2)) {
		return undefined;
	}
	if (!obj2 || typeof obj2 !== "object") {
		return obj2;
	}
	/* basically we are mapping on first obj because api response have some extra key as well */
	Object.keys(obj1).map((i) => {
		if (obj1[i] !== obj2[i]) {
			result[i] = obj1[i];
		}
	});
	return result;
};

//Stylish code fetch
export const getURLstylistCode = () => {

	var url = window.location.href;
	const urlWithoutParams = url.split("?")[0];
  const uniquecode = urlWithoutParams.split("/")[3];
	return (uniquecode);
}

export const getURLwithstylistCode = () => {
	var url = window.location.href;
  const urlWithoutParams = url.split("?")[0];
  const uniquecode = urlWithoutParams.split("/")[3];
	return (`stylist_unique_code=${uniquecode}`);	
  
}

export const getFormattedNumber = (number) => {
    let num = number.replace(/\s+/g, "");
    

    if (num.length === 9) {
      return num.slice(0, 3) + " " + num?.slice(3, 6) + " " + num?.slice(6);
    } else {
      return num.slice(0, 4) + " " + num?.slice(4, 7) + " " + num?.slice(7);
    }
  };


  export const formatTime = (timeString)=> {
    // const [hourString, minute] = timeString.split(":");
    // const hour = +hourString % 24;
    // // return (hour % 12 || 12) + " : " + minute + (hour < 12 ? " AM" : " PM");
    
    return moment(timeString, "HH:mm").format("hh : mm A");
  }



// export const FilterDates = (date: any, format: any) => {

    
// 	// return moment(date.slice(0,9)).format(format);
// }

