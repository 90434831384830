import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "../../global/ui/Button";
import InputField from "../../global/ui/InputField";
import { apiConstants } from "../../utils/constants/apiConstants";
import { ROUTES_CONSTANTS } from "../../utils/constants/routesString";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

import { makeGetCall, makePostCall } from "../../effects/requests";
import endpoints from "../../utils/endpoints";

import useStore from "../../zustandstore";
import {
  getURLstylistCode,
  getURLwithstylistCode,
  isNotEmpty,
  isNotEmptyString,
} from "../../utils/globalFunctions";
import GooglePlacesSearchBar from "../../global/ui/GooglePlacesSearchBar";
import useCurrentAddress from "../../hooks/useCurrentAddress";
import Navigation from "./Navigation";

import { useParams } from "react-router-dom";
import { images } from "../../utils/constants/assets";
import { getLocalStorage, showToast } from "../../utils";

interface LocationProps {
  handleNext: () => void;
  control?: any;
  errors?: any;
  watch?: any;
  setValue?: any;
  setTotalAmountArr?: any;
  getValues?: any;
  // stylistlocationData?: any;
  // setStylistLocationData?: any;
}

type locationDataType = {
  full_name: string;
  profile_picture: string;
  address;
  setValue: string;
};

export default function Location(props: LocationProps) {
  const {
    control,
    errors,
    handleNext,
    watch,
    setValue,
    getValues,
    setTotalAmountArr,
    // stylistlocationData,
    // setStylistLocationData,
  } = props;

  const [showPopup, setShowPopup] = useState(false);

  const { locationNotFound, setLocationNotFound } = useStore(
    (state: any) => state
  );

  const mainstylistLocationData = useStore(
    (state: any) => state.stylistLocationData
  );

  const updateStylistLocationData = useStore(
    (state: any) => state.setStylistLocationData
  );

  //Initalizing location_id in zustandstore when app is loaded for the first time
  const { setLocationId, location_id, setTimezoneId, setSelectedService } = useStore(
    (state: any) => state
  );

  async function getResponse() {
    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.stylist_location}?${getURLwithstylistCode()}`,
      });

      const data = await apiResponse;
      updateStylistLocationData(data);
      if (data.status.code === 200) {
        if (!isNotEmptyString(location_id)) {
          setLocationId(data?.results?.[0]?.id);
        }

        if (
          !isNotEmptyString(getValues(apiConstants.location)) &&
          !isNotEmptyString(getValues(apiConstants.custom_location))
        ) {
          setValue(apiConstants.location, data?.results[0].id);
          setValue(apiConstants.timezone, data?.results[0].timezone);
        }
      } else {
        showToast(data.status.message, "error");
      }
      setSelectedService([]);
    } catch (err) {
      console.log(err);
    }
  }

  async function getTimeZoneInMbl() {
    let apiPayload = {
      location: [getValues(apiConstants.lat), getValues(apiConstants.lng)],
    };

    try {
      const apiResponse = await makePostCall({
        url: endpoints.timezone,
        apiPayload,
      });

      if (apiResponse.status.code === 200) {
        setTimezoneId(apiResponse?.data?.tz);
      } else {
        showToast(apiResponse.status.message, "error");
      }
    } catch (err) {}
  }

  useEffect(() => {
    setLocationId(null);
    getResponse();
  }, []);

  useEffect(() => {
    if (getValues(apiConstants.lat) != null) {
      getTimeZoneInMbl();
    }
  }, [getValues(apiConstants.lat)]);

  const handleSubmit = () => {
    setTimezoneId(getValues(apiConstants.timezone));
    setTotalAmountArr([]);
    handleNext();
  };

  const {
    curraddress,
    lat,
    lng,
    city,
    country,
    country_code,
    state,
    state_code,
    zip_code,
    error,
  } = useCurrentAddress();

  useEffect(() => {
    if (!getValues(apiConstants.custom_location) && showPopup) {
      setValue(apiConstants.custom_location, curraddress);
      setValue(apiConstants.lat, lat);
      setValue(apiConstants.lng, lng);
      setValue(apiConstants.custom_location_object, {
        address: curraddress,
        country: country,
        country_code: country_code,
        location: [lat, lng],
        city: city,
        zip_code: zip_code,
        state: state,
        state_code: state_code,
      });
    }
  }, [curraddress, showPopup]);

  const handlePopClick = () => {
    setShowPopup(!showPopup);
    // const geo = navigator.geolocation;

    // setLocationNotFound(true);
  };

  const handleLocationError = () => {
    setLocationNotFound(false);
  };

  const radioValue = watch(apiConstants.location);

  const MobileBookingBtn = () => {
    setShowPopup(false);
  };

  const showInMapClicked = (location) => {
    window.open(
      "https://maps.google.com?q=" +
        location.location[0] +
        "," +
        location.location[1],
      "_blank"
    );
  };

  const access_token = getLocalStorage("access_token");

  const setShowNav = useStore((state: any) => state.setShowNav);
  const navOpen = () => {
    setShowNav(true);
  };

  const [one, setOne] = useState("");
  const navigate = useNavigate();
  const re_book_past = () => {
    // const urlParams = new URLSearchParams(window.location.search);
    // let uc = urlParams.get("stylist_unique_code");
    navigate(`/${getURLstylistCode()}/bookings/?rebook`);
    // setCondition(true);
    // setbookingType("past");
    setOne("true");
  };

  const [isMblDisabled, setIsMblDisabled] = useState(false);

  return (
    <div className="step_1">
      <div className="upper-wrapper">
        {access_token ? (
          <div className="go-back">
            <div></div>
            <div className="user-icon" onClick={navOpen}>
              <img src={images.user_1} alt="" />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="prsn-img">
          <img
            src={mainstylistLocationData?.stylist_info?.profile_picture}
            alt=""
          />
        </div>
        <h1>
          Book an appointment with{" "}
          {mainstylistLocationData?.stylist_info?.full_name}
        </h1>
        <p>Please select your preferred booking location.</p>

        <div className="address-select-outer">
          {mainstylistLocationData?.results?.map((location, index) => {
            return (
              <div
                key={`location_${index}`}
                className={
                  radioValue === location.id ? "address active" : "address"
                }
              >
                <InputField
                  key={`location_input_${index}`}
                  name={apiConstants.location}
                  type="radio"
                  control={control}
                  customOnChange={(e) => {
                    setValue(apiConstants.location, e.target.value);
                    setLocationId(getValues().location);
                    setValue(apiConstants.timezone, location?.timezone);
                    if (getValues(apiConstants.location)) {
                      setValue(apiConstants.custom_location, "");
                    }
                  }}
                  id={location.id}
                  // errors={index === 0 ? errors : ""}
                  // inputLabel={item.value}
                />

                <div
                  className="g-map"
                  onClick={() => showInMapClicked(location)}
                >
                  <img src={location.location_image} alt="Google Map" />
                </div>

                <div>
                  <span className="name">{location.name}</span>
                  <p>{location.address}</p>
                </div>
                <div className="radio-btn-img"></div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="bottom-wrapper">
        {mainstylistLocationData?.stylist_info
          ?.is_consumer_preferred_address_allowed && (
          <div className="mbl-booking">
            <div
              className={
                radioValue === "mbl_booking" ? "address active" : "address"
              }
              onClick={handlePopClick}
            >
              <InputField
                // key={index}
                name={apiConstants.location}
                type="radio"
                control={control}
                customOnChange={(event) => {
                  const inputValue = event.target.value;
                  setValue(apiConstants.location, inputValue);
                }}
                id="mbl_booking"
                // errors={errors}
              />

              <div>
                <span className="name">Mobile Booking</span>
                <p>
                  Proceed with mobile booking for any at home or on the go
                  requests.
                </p>
              </div>

              <div className="radio-btn-img"></div>
            </div>
          </div>
        )}
        {getValues(apiConstants.custom_location) && (
          <div className="fetched-booking-data">
            <div className="pop-address">
              <div>
                <span>Address</span>
                <b onClick={handlePopClick}>Edit</b>
              </div>

              <InputField
                name={apiConstants.custom_location}
                type="text"
                control={control}
              />
            </div>
          </div>
        )}
        <Button
          className="continue-btn"
          type="submit"
          disabled={
            watch(apiConstants.custom_location) === null ||
            (watch(apiConstants.custom_location) === "" &&
              radioValue === "mbl_booking")
              ? true
              : false
          }
          btnTxt={"Continue"}
          onClick={handleSubmit}
        />

        {access_token ? (
          <Button
            className="rebook-btn"
            btnTxt={"Re-book a past appointment"}
            onClick={re_book_past}
          />
        ) : (
          ""
        )}

        {/* <Link to={{
                    pathname: ROUTES_CONSTANTS.signup,
                    // search: "?sort=name",
                    // hash: "#the-hash",
                    state: { handleSubmit }
                }}>Re-book a past appointment</Link> */}
        {/* <div>{bears}</div> */}

        {showPopup && (
          <div className="mbl-popup-outer">
            <div className="overlay" onClick={handlePopClick}></div>
            <div className="mbl-popup">
              <div className="pop-address">
                <span>Address</span>

                <GooglePlacesSearchBar
                  onChangeLocation={(
                    lat: string,
                    lng: string,
                    address: string,
                    zipCode: number,
                    region: string,
                    country: string,
                    city: string,
                    country_code: string,
                    state: string,
                    state_code: string
                  ) => {
                    setValue(apiConstants.custom_location, address);
                    setValue(apiConstants.lat, lat);
                    setValue(apiConstants.lng, lng);

                    setValue(apiConstants.custom_location_object, {
                      address: address,
                      country: country,
                      country_code: country_code,
                      location: [lat, lng],
                      city: city,
                      zip_code: zipCode,
                      state: state,
                      state_code: state_code,
                    });
                  }}
                  name={apiConstants.custom_location}
                  control={control}
                  value={getValues(apiConstants.address) || ""}
                  // onChanged={(evt: any) => {
                  //     setValue(apiConstants.address, evt.target.value);
                  // }}
                  // errors={errors}
                  label={"My location"}
                  className="search_input"
                  placeholder="Search home address"
                />
              </div>
              <div onClick={handlePopClick} className="close-btn">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.00004 7.4001L1.70004 11.7001C1.31344 12.0867 0.686636 12.0867 0.300037 11.7001C-0.0865626 11.3135 -0.0865635 10.6867 0.300036 10.3001L4.60004 6.0001L0.300037 1.7001C-0.0865626 1.3135 -0.0865627 0.686697 0.300037 0.300098C0.686636 -0.0865017 1.31344 -0.086501 1.70004 0.300098L6.00004 4.6001L10.3 0.300098C10.6866 -0.0865016 11.3134 -0.0865017 11.7 0.300098C12.0866 0.686697 12.0866 1.3135 11.7 1.7001L7.40004 6.0001L11.7 10.3001C12.0866 10.6867 12.0866 11.3135 11.7 11.7001C11.3134 12.0867 10.6866 12.0867 10.3 11.7001L6.00004 7.4001Z"
                    fill="black"
                  />
                </svg>
              </div>

              {locationNotFound && (
                <div className=" locationnotfound">
                  <div className="locationnotfound-popup">
                    <span>{STRING_CONSTANTS.your_location_is_off}</span>
                  </div>
                </div>
              )}
              <Button
                // onClick={reBookButton}
                disabled={!watch(apiConstants.custom_location)}
                className="continue-btn"
                type="submit"
                btnTxt={"Confirm"}
                onClick={MobileBookingBtn}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}