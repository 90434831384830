import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../global/ui/Button";
import {
  convertLocal,
  convertToTimeZoneDate,
  convertToTimeZoneTime,
  getCurrentTimeZone,
  getURLwithstylistCode,
} from "../../utils/globalFunctions";
import useStore from "../../zustandstore";
import { makeGetCall, makePatchCall } from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { images } from "../../utils/constants/assets";
import Cancellation from "./Cancellation";
import Cancelled from "./Cancelled";
import { showToast } from "../../utils";
import { apiConstants } from "../../utils/constants/apiConstants";
import moment from "moment";
export default function Success(props) {
  const { setStepNo, setValue } = props;
  const {
    bookingAPIData,
    mainstylistLocationData,
    setReschedule,
    setBookingDetail,
    setRescheduleBookingID,
    setStylistUniqueCode,
    updateStylistLocationData,
    setBookingId,
    setSelectedDate,
  } = useStore((state: any) => state);

  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" +
        bookingAPIData.location[0] +
        "," +
        bookingAPIData.location[1],
      "_blank"
    );
  };

  const handleNavigation = () => {
    setStepNo(3);
    setReschedule(true);
    setRescheduleBookingID(bookingAPIData.id);
    setValue(apiConstants.calander, null);
    setSelectedDate(null);
    // setPrevPage(2);
    // setsignin(true);
  };

  const navigate = useNavigate();

  const newBooking = () => {
    window.location.reload();
  };

  const setShowNav = useStore((state: any) => state.setShowNav);
  const navOpen = () => {
    setShowNav(true);
  };

  const [mainstylistLocationDataa, setMainstylistLocationDataa] =
    useState<any>();

  //+++++++++++
  async function getResponse() {
    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.stylist_location}?${getURLwithstylistCode()}`,
      });

      const data = await apiResponse;
      console.log("data", data);
      setMainstylistLocationDataa(data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getResponse();
  }, []);

  const [showPopup, setShowPopup] = useState(false);

  const cancelpopup = () => {
    setShowPopup(!showPopup);
  };

  const [cancelBooking, setCancelBooking] = useState(false);

  const cancelBook = () => {
    setCancelBooking(!cancelBooking);
  };

  const [disableCancel, setDisableCancel] = useState(false);

  const handleConfirm = async () => {
    setDisableCancel(true);
    let bookingAPIPayload: any = {
      online_status: "2",
      is_deleted: "true",
    };

    const urlParams = new URLSearchParams(window.location.search);

    setBookingId(urlParams.get("id"));

    const apiResponse = await makePatchCall({
      url: `${endpoints.booking}${
        bookingAPIData?.id
      }/?${getURLwithstylistCode()}`,
      apiPayload: bookingAPIPayload,
    });

    if (apiResponse.status.code === 200) {
      setCancelBooking(false);
      setShowCancel(true);
    } else {
      showToast(apiResponse.status.message, "error");
    }
  };

  const [showCancel, setShowCancel] = useState(false);

  let cancelTimings;
  if (bookingAPIData?.modified_at) {
    cancelTimings =
      moment(bookingAPIData?.start_dt).subtract(
        mainstylistLocationDataa?.stylist_info?.minimum_cancellation_time_frame,
        "days"
      ) <= moment() && moment() <= moment(bookingAPIData?.start_dt);
  } else {
    cancelTimings =
      moment(bookingAPIData?.start_dt).subtract(
        mainstylistLocationDataa?.stylist_info?.minimum_cancellation_time_frame,
        "days"
      ) <= moment() && moment() <= moment(bookingAPIData?.start_dt);
  }

  // booking.start - 2 days <= current_time <= booking.start_dt

  return (
    <>
      {showCancel && <Cancelled />}

      {!showCancel && (
        <div className="sign_up_screen thankyou">
          {cancelBooking && (
            <div className="cancel-popup">
              <div className="overlay"></div>
              <div className="cancel-inner">
                <h1>{STRING_CONSTANTS.confirmed_heading}</h1>
                <p>
                  {STRING_CONSTANTS.confirmed_sure}

                  {STRING_CONSTANTS.confirmed_final}
                  <br />
                  {mainstylistLocationDataa?.stylist_info
                    ?.cancellation_charge_amount > 0 &&
                    mainstylistLocationDataa?.stylist_info
                      ?.is_cancellation_charge_active &&
                    cancelTimings && (
                      <>
                        <br /> <span>{STRING_CONSTANTS.please_note}</span>
                        {
                          bookingAPIData?.cancellation_charges
                        }{" "}
                        {STRING_CONSTANTS.cancel_info}{" "}
                        {bookingAPIData?.consumer_payment_method_last4_digit}.
                      </>
                    )}
                </p>

                <div className="cancel-buttons">
                  <span
                    onClick={() => handleConfirm()}
                    className={disableCancel ? "disabled" : ""}
                  >
                    Yes
                  </span>
                  <span onClick={cancelBook}>No</span>
                </div>
              </div>
            </div>
          )}
          <div className="upper-wrapper">
            <div className="back-logo">
              <div className="go-back">
                <div className="user-icon" onClick={navOpen}>
                  <img src={images.user_1} alt="" />
                </div>
              </div>
            </div>
            <div className="thankyou-section">
              <div className="thankyou-inner">
                <div className="thanks-top">
                  <div className="msg-box">
                    <img src={images.confirm_icon} alt="" />
                    <span>{STRING_CONSTANTS.appointment_requested}</span>
                    {/* <b>{STRING_CONSTANTS.requested_text}</b> */}
                  </div>

                  <div className="time-outer">
                    <p className="confirm-text">
                      {STRING_CONSTANTS.you_will}{" "}
                      {mainstylistLocationDataa?.stylist_info?.full_name}{" "}
                      {STRING_CONSTANTS.approves_your}{" "}
                    </p>

                    <div className="date-time">
                      <div className="date">
                        <img src={images.date_icon} alt="" />

                        {convertToTimeZoneDate(bookingAPIData?.start_dt, bookingAPIData?.timezone, "Do MMM YYYY")}
                      </div>
                      <div className="time">
                        <img src={images.time_icon} alt="" />
                        {convertToTimeZoneTime(
                          bookingAPIData?.start_dt,
                          bookingAPIData?.timezone,
                          "hh:mm A"
                        )}- {convertToTimeZoneTime(bookingAPIData?.end_dt, bookingAPIData?.timezone, "hh:mm A")}
                      </div>
                    </div>
                    <div className="address-map">
                      <div className="adrs">
                        <img src={images.address_icon} alt="" />
                        {bookingAPIData?.address}
                      </div>
                      <div className="map" onClick={() => showInMapClicked()}>
                        <img src={images.thanks_icon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="thanks-bottom">
                  {STRING_CONSTANTS.booking_id_text}{" "}
                  {bookingAPIData?.booking_id}
                </div>
              </div>
              <div className="service-summery">
                <span>{STRING_CONSTANTS.service_summary}</span>

                <ul>
                  {bookingAPIData?.services?.map((item) => {
                    return (
                      <li key={item.service_id}>
                        <span>
                          {item.name || item.service__name}
                          {item.duration || item.service__duration
                            ? ","
                            : ""}{" "}
                          {item.duration || item.service__duration}{" "}
                          {item.duration || item.service__duration
                            ? STRING_CONSTANTS.minutes
                            : ""}
                        </span>
                        <b>${item.price}</b>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {bookingAPIData?.products?.length > 0 && (
                <div className="service-summery">
                  <span>{STRING_CONSTANTS.product_heading}</span>

                  <ul>
                    {bookingAPIData?.products?.map((item) => {
                      return (
                        <li key={item.product_id}>
                          <span>{item.product || item.product__name}</span>
                          <b>${item.price}</b>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {mainstylistLocationDataa?.stylist_info
                ?.is_online_deposit_allowed && (
                <div className="service-summery cancel-info">
                  <span>
                    <img src={images.cancel_info} alt="" />
                    {STRING_CONSTANTS.deposit_info}
                  </span>
                  <ul>
                    <li>
                      <span>{STRING_CONSTANTS.this_booking_requires}</span>
                    </li>
                  </ul>
                </div>
              )}

              {mainstylistLocationDataa?.stylist_info
                ?.is_cancellation_charge_active && (
                <div className="service-summery cancel-info">
                  <span>
                    <img src={images.cancel_info} alt="" />
                    {STRING_CONSTANTS.cancel_info_text}
                  </span>
                  <ul>
                    <li>
                      <span>
                        {STRING_CONSTANTS.cancel_anytime}{" "}
                        {mainstylistLocationDataa?.stylist_info
                          ?.minimum_cancellation_time_frame * 24}
                        {STRING_CONSTANTS.cancel_driven}{" "}
                        <span onClick={cancelpopup} className="cancel-policy">
                          {STRING_CONSTANTS.payment_cancellation}
                        </span>{" "}
                        {STRING_CONSTANTS.cancel_before}
                      </span>
                      {showPopup && (
                        <div className="cancel-popup">
                          <div className="overlay" onClick={cancelpopup}></div>
                          <div className="cancel-inner">
                            <h2>
                              {STRING_CONSTANTS.cancellation_policy_heading}
                            </h2>
                            <p>
                              {" "}
                              {
                                mainstylistLocationDataa?.stylist_info
                                  ?.cancellation_policy
                              }
                            </p>
                          </div>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              )}
              <div className="service-summery cancel-info need-assist">
                <span>
                  <img src={images.need_assist_icon} alt="" />
                  {STRING_CONSTANTS.need_assist}
                </span>
                <ul>
                  <li>
                    <span>
                      {STRING_CONSTANTS.contact_us_via}{" "}
                      <a
                        href={`mailto:${mainstylistLocationDataa?.stylist_info?.email}`}
                      >
                        {mainstylistLocationDataa?.stylist_info?.email}
                      </a>{" "}
                      {STRING_CONSTANTS.or_over_phone}{" "}
                      <a
                        href={`tel:${mainstylistLocationDataa?.stylist_info?.mobile_number}`}
                      >
                        {mainstylistLocationDataa?.stylist_info?.mobile_number}
                      </a>
                      .
                    </span>
                  </li>
                </ul>
              </div>
              <div className="service-summery need-assist follow-social">
                {mainstylistLocationDataa?.stylist_info?.instagram_handle ||
                  mainstylistLocationDataa?.stylist_info?.facebook_handle ||
                  (mainstylistLocationDataa?.stylist_info?.tiktok_handle && (
                    <span>
                      Follow {mainstylistLocationDataa?.stylist_info?.full_name}{" "}
                      socials
                    </span>
                  ))}

                <ul className="social-links">
                  {mainstylistLocationDataa?.stylist_info?.instagram_handle && (
                    <li>
                      <a
                        href={
                          mainstylistLocationDataa?.stylist_info
                            ?.instagram_handle
                        }
                        target="_blank"
                      >
                        <img src={images.insta_icon} alt="" />
                      </a>
                    </li>
                  )}
                  {mainstylistLocationDataa?.stylist_info?.facebook_handle && (
                    <li>
                      <a
                        href={
                          mainstylistLocationDataa?.stylist_info
                            ?.facebook_handle
                        }
                        target="_blank"
                      >
                        <img src={images.fb_icon} alt="" />
                      </a>
                    </li>
                  )}

                  {mainstylistLocationDataa?.stylist_info?.tiktok_handle && (
                    <li>
                      <a
                        href={
                          mainstylistLocationDataa?.stylist_info?.tiktok_handle
                        }
                        target="_blank"
                      >
                        <img src={images.tiktok_icon} alt="" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="buttons">
                <Button
                  className="continue-btn re-sch"
                  type="submit"
                  btnTxt={"Cancel Booking"}
                  isLoading={false}
                  onClick={() => cancelBook()}
                />
                <Button
                  className="continue-btn re-sch"
                  type="submit"
                  btnTxt={"Re-schedule"}
                  isLoading={false}
                  onClick={() => handleNavigation()}
                />
                {/* </Link>  */}

                {/* <Link to=""></Link> */}

                <Button
                  className="continue-btn"
                  type="submit"
                  btnTxt={"New Booking"}
                  isLoading={false}
                  onClick={() => newBooking()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
