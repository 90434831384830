import moment from "moment";
import React, { useEffect, useState } from "react";
import { SingleDatePicker } from "react-dates";
import { Controller, useController, useForm } from "react-hook-form";
import { getAvailableTiming } from "../../api";
import { makeGetCall, makePatchCall } from "../../effects/requests";
import Button from "../../global/ui/Button";
import { apiConstants } from "../../utils/constants/apiConstants";
import endpoints from "../../utils/endpoints";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import useStore from "../../zustandstore";
import ScheduleTime from "./ScheduleTime";
import { useNavigate } from "react-router-dom";
import {
  getURLstylistCode,
  getURLwithstylistCode,
} from "../../utils/globalFunctions";
import { images } from "../../utils/constants/assets";
import { showToast } from "../../utils";


const RescheduleCalender = () => {
  const { setValue, getValues, control } = useForm();
  const {
    setStylistLocationData,
    stylistUniqueCode,
    stylistLocationData,
    setSelectedDate,
    selectedDate,
    tz,
    currentTime,
    selectedServices,
    setBookingDuration,
    bookingDuration,
    setTiming,
    setSelectedService,
    selectedTime,
    bookingAPIData,
    setBookingAPIData,
    setRebookLocation,
    rebookLocation,
    rescheduleBookingID,
    setSelectedTime,
    timezone_id,
    setTimezoneId,
  } = useStore((state: any) => state);

  const [localSelectedDate, setLocalSelectedDate] = useState<any>(null);
  const [isDateSelected, setDateSelected] = useState(false);
  const [timingError, setTimingError] = useState(false);
  const [timingApi, setTimingApi] = useState<any>("");
  const [dataShowing, setDataShowing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const setStoreTiming = useStore((state: any) => state.setTiming);
  const urlParams = new URLSearchParams(window.location.search);
  const [rescheduleId, setRescheduleId] = useState<any>("");
  const [rebookId, setRebookId] = useState<any>("");

  const [locationId, setLocationId] = useState<any>(null);
  const [totalDuration, setTotalDuration] = useState<any>(null);
  const [fullDays, setFullDays] = useState<any>(null);
  const [unFitDays, setUnfitDays] = useState<any>(null);
  const [monthSelected, setMonthSelected] = useState<any>(moment().format("MM-YYYY"));

  useEffect(() => {
    if (localSelectedDate) {
      handleGetAvailableTiming();
    }
  }, [localSelectedDate]);

  useEffect(() => {
    setBookingAPIData(null);
    setSelectedDate(null);
    setTiming(null);
    getRes();
    setSelectedTime(null);
  }, []);

  useEffect(() => {
    if(locationId){
      handleGetMonthlyFullDays(monthSelected);
    }
  }, [monthSelected, locationId]);

  useEffect(() => {
    if (fullDays) {
      getUnfitDates(fullDays);
    }
  }, [fullDays]);

  const getRes = () => {
    if (window.location.href.includes("reschedule")) {
      setRescheduleId(urlParams.get("id"));
      makeGetCall({
        url: `${endpoints.booking}${urlParams.get(
          "id"
        )}/?${getURLwithstylistCode()}`,
      }).then((res) => {
        setSelectedService(res?.data?.services);
        setRebookLocation(res?.data);
        setTimezoneId(res?.data?.timezone)
        setLocationId(res?.data?.location_selected)

        if (res?.data?.location_selected === null) {
          mblBookingResponse();
          setDataShowing(true);
        } else {
          getStylistData();
        }
      });
    } else if (window.location.href.includes("rebook")) {
      setRebookId(urlParams.get("id"));
      makeGetCall({
        url: `${endpoints.booking}${urlParams.get(
          "id"
        )}/?${getURLwithstylistCode()}`,
      }).then((res) => {
        setSelectedService(res?.data?.services);
        setRebookLocation(res?.data);
        setTimezoneId(res?.data?.timezone)
        setLocationId(res?.data?.location_selected)

        if (res?.data?.location_selected === null) {
          mblBookingResponse();

          setDataShowing(true);
        } else {
          getStylistData();
        }
      });
    }
  };
  
  async function getStylistData() {
    try {
      const apiResponse = await makeGetCall({
        url: `${endpoints.stylist_location}?${getURLwithstylistCode()}`,
        // params: stylist_unique_code,
      });

      const data = await apiResponse;

      setStylistLocationData(data);
    } catch (err) {}
  }

  async function mblBookingResponse() {
    try {
      const apiResponse = await makeGetCall({
        url: `${
          endpoints.stylist_location
        }?${getURLwithstylistCode()}&only_mobile_booking=true`,
      });

      const data = await apiResponse;
      setStylistLocationData(data);
    } catch (err) {
      console.log(err);
    }
  }

  let booking_duration = moment(rebookLocation?.end_dt).diff(
    moment(rebookLocation?.start_dt),
    "minutes"
  );

  const handleGetAvailableTiming = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentTime = moment().format("HH:mm");

    // const booking_duration = bookingDuration.duration;
    setSelectedDate(selectedDate, tz, currentTime);

    let gettimingparameters = {
      tz: tz,
      // stylist_unique_code: getURLstylistCode(),
      selected_dt: `${localSelectedDate?.format("YYYY-MM-DD")} ${currentTime}`,
      location_id: rebookLocation?.location_selected,
      // booking_duration: booking_duration,
      booking_duration,

      ...(window.location.href.includes("reschedule") && {
        // booking_to_update: rescheduleBookingID,
        booking_to_update: rebookLocation?.id,
      }),
    };
    getAvailableTiming(gettimingparameters).then((res) => {
      // if (res?.status?.code === 403) {
      //   setTimingError(res?.status?.message);
      //   return;
      // }
      if (res?.status?.code === 200) {
        setStoreTiming(res?.data);
        setTimingError(false);
        // return;
      } else if (res?.status?.code === 403) {
        setTimingError(true);
        // showToast(res?.status?.message, "error");
      }
      setTiming(res?.data);
      setTimingApi(res);
    });
  };

  const handleBookingDuration = () => {
    const totalDuration = selectedServices
      .map((item) => item.duration)
      .reduce((acc, curr) => acc + curr);
    setBookingDuration("00 : 00  AM", totalDuration);
  };

  //+++Handle Date Change+++
  const handleDateChange = (date) => {
    handleBookingDuration();

    setSelectedIndex(null);
    setLocalSelectedDate(date);
    setDateSelected(true);
    onChange(date);
    setSelectedTime(null);
    setSelectedDate(date?.format("YYYY-MM-DD"), tz, currentTime);
  };

  //+++Day Blocking functionality++++
  const changeMonth = (month) => {
    setMonthSelected(month.format("MM-YYYY"));
  }

  const handleGetMonthlyFullDays = async (newMonth) => {
    const monthlyParams = {
      stylistUniqueCode: getURLstylistCode(),
      monthYear: newMonth,
      locationId: rebookLocation?.location_selected,
    };

    setTotalDuration(booking_duration);

    try {
      const response = await makeGetCall({
        url: endpoints.get_full_days,
        params: monthlyParams,
      });

      const { data } = response;
      setFullDays(data);

    } catch (error) {
      showToast("Error", error.message);
    }
  };

  let workdays;
  dataShowing
    ? (workdays = stylistLocationData?.results[0]?.working_hours)
    : (workdays = stylistLocationData?.results?.filter(
        (item) => item.id === rebookLocation?.location_selected
      )[0]?.working_hours);

  const temp = workdays?.filter((item) => item.is_active === false);

  let dayOff: number[] = []; // Stores number array where 0 is Monday and 6 is Sunday
  temp?.forEach((item) => {
    dayOff.push((item.weekday + 1) % 7);
  });

  const getUnfitDates = (fullDays) => {
    let unfitDates: any[] = [];
    fullDays?.forEach((item) => {
      if (moment.duration(totalDuration, 'minutes').asSeconds() > item[1]) {
        unfitDates.push(moment(item[0]).format('YYYY-MM-DD'));
      }
    });
    setUnfitDays(unfitDates);
  };

  const isDayBlocked = (day) => {
    return dayOff.includes(day.days()) || unFitDays?.includes(day.format('YYYY-MM-DD'));
  };
  const name = "datetemp";
  //++++++++CONTROLLER+++++
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    // errors,
  });

  const navigate = useNavigate();
  //++++++Submitting Form+++++
  const handleSubmit = () => {
    if (rescheduleId) {
      const time = `${selectedTime
        .trim()
        .slice(0, 7)
        .replace(/\s/g, "")} ${selectedTime.slice(8)}`;
      const date = new Date(`01/01/2022 ${selectedTime}`);
      
      const startTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const start_dt = `${selectedDate} ${startTime}`;
      //++++calculate end time
      const [hours, minutes] = startTime.split(":"); // split the input time into hours and minutes
      const tempDate = new Date();

      tempDate.setHours(parseInt(hours)); // set the hours of the date object to the hours from the input time

      tempDate.setMinutes(parseInt(minutes) + booking_duration);

      const endTime = tempDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const end_dt = `${selectedDate} ${endTime}`;

      makePatchCall({
        url: `${endpoints.booking}${rescheduleId}/?${getURLwithstylistCode()}`,
        apiPayload: {
          start_dt: start_dt,
          end_dt: end_dt,
        },
        xTimezone: tz,
      }).then((res) => {
        if (res.status.code != 200) {
          showToast(res.status.message, "error");
        } else {
          setBookingAPIData(res?.data);
          navigate(`/${getURLstylistCode()}/rescheduleSucess`);
          // navigate(
          //   `/rescheduleSucess/?${getURLstylistCode()}&id=${bookingAPIData.id}`
          // );
        }
      });
    } else if (rebookId) {
      // return;

      navigate(`/${getURLstylistCode()}/rebookpayment/?${getURLstylistCode()}`);
    }
  };

  return (
    <div className="booking-wrapper">
      <div className="step_3 sign_up_screen">
        <div className="upper-wrapper">
          <div className="go-back">
            <div
              onClick={() => {
                navigate(-1);
                setDateSelected(false);
                setSelectedDate(false);
              }}
            >
              <img src={images.go_back} alt="" />
            </div>
          </div>
          <p className="appoint-text">
            Select your new appointment date & time.
          </p>
          <form>
            <div className="Calender-picker">
              {/* <label htmlFor="date">Date:</label> */}
              <Controller
                name={name}
                control={control}
                render={() => {
                  return (
                    <SingleDatePicker
                      id="my-datepicker-id"
                      date={value}
                      onDateChange={(date) => handleDateChange(date)}
                      onNextMonthClick={changeMonth}
                      onPrevMonthClick={changeMonth}
                      focused={true}
                      onFocusChange={() => {}}
                      numberOfMonths={1}
                      initialVisibleMonth={() => value || moment()}
                      isDayBlocked={isDayBlocked}
                    />
                  );
                }}
              />
            </div>
            <div className="timing-bottom-mb">
              {timingError ? (
                <span className="pls-date">{timingApi?.status?.message}</span>
              ) : // ) : (isDateSelected || selectedDate) && timingApi ? (
              isDateSelected || selectedDate ? (
                <ScheduleTime
                  control={control}
                  name={apiConstants.time}
                  // errors={errors}
                  setValue={setValue}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  // handleTimeChange={handleTimeChange}
                  getValues={getValues}
                  timingError={timingError}
                />
              ) : (
                <span className="pls-date">{STRING_CONSTANTS.select_date}</span>
              )}
              {/* {selectedTime && selectedDate && ( */}
              <div className="bottom-wrapper bottom-shadow">
                <Button
                  className="continue-btn"
                  disabled={selectedTime && selectedDate ? false : true}
                  // className={hasError ? "error continue-btn" : "continue-btn"}
                  type="button"
                  btnTxt={"Confirm Time & Date"}
                  onClick={handleSubmit}
                />
                {/* Confirm Time & Date */}
              </div>
              {/* )} */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RescheduleCalender;
