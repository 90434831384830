import React, { useEffect, useRef, useState } from "react";
import Button from "../../global/ui/Button";

import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { setLocalStorage, showToast } from "../../utils";
import { isEmptyOrNullOrUndefined } from "../../utils/dateUtils";
import { apiConstants } from "../../utils/constants/apiConstants";
import {
  makeGetCall,
  makePatchCall,
  makePostCall,
} from "../../effects/requests";
import endpoints from "../../utils/endpoints";
import useStore from "../../zustandstore";

interface ReVeriftyOtpProps {
  togglePopup?: any;
  showPopup?: any;
  setShowPopup?: any;
  watch?: any;
  getValues?: any;
  handleSubmit?: any;
  getProfileDetail?: any;
  getResponse?: any;
  setBookingDetail?: any;
}
export default function ReVerifyOtp(props: ReVeriftyOtpProps) {
  const {
    togglePopup,
    showPopup,
    setShowPopup,
    watch,
    getValues,
    handleSubmit,
    getProfileDetail,
    setBookingDetail,
    getResponse,
  } = props;

  const AuthInputRef = useRef<AuthCodeRef>(null);
  const [otp, setOtp] = useState("");

  // const [timer, setTimer] = useState(60);
  const [resendOtp, setResendOtp] = useState(false);
  const [isError, setIsError] = useState(false);
  let timerId: any = useRef(null);

  const [checkUserRegistered, setCheckUserRegistered] = useState<any>(false);

  //   const num = `${getValues(apiConstants.country_code)}${getValues(
  //     apiConstants.mobile_number
  //   )}`;

  //   const mobileNumber = parseInt(num.toString().replace(/\s/g, ""));

  async function checkUserApi() {
    const phone_check = {
      mobile_number: watch("mobile_number").replace(/\s/g, ""),
    };

    try {
      const apiResponse = await makeGetCall({
        url: endpoints.otp,
        params: phone_check,
      });

      const data = await apiResponse;

      if (data.status.code === 200) {
        setCheckUserRegistered(data?.data);
      } else {
        showToast(data.status.message, "error");
      }
    } catch (err) {}
  }

  async function verifyOtpApi() {
    let apiPayload = {
      token: checkUserRegistered?.token,
      is_token_required: "false",
      otp: otp,
    };

    try {
      const apiResponse = await makePostCall({
        url: endpoints.otp,
        apiPayload,
      });

      if (apiResponse.status.code === 200) {
      } else {
        showToast(apiResponse.status.message, "error");
      }
    } catch (err) {}
  }

  useEffect(() => {
    checkUserApi();
  }, []);

  const [mins, setMins] = useState(0);
  const [secs, setSecs] = useState(60);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (secs <= 0) {
        if (mins <= 0) return;
        else {
          setMins((m) => m - 1);
          setSecs(59);
        }
      } else setSecs((s) => s - 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, [secs, mins]);

  // const handleStart = () => {

  //   timerId.current = setInterval(() => {
  //     if (timer >= 0) {
  //       setTimer((timer) => timer - 1);
  //     } else {
  //       clearInterval(timerId.current);
  //     }
  //   }, 1000);
  // };

  const showReset = () => {
    setResendOtp(true);
  };

  useEffect(() => {
    if (secs === 0) {
      showReset();
    }
  }, [secs]);

  const handleChange = (enteredOtp: any) => {
    setOtp(enteredOtp);
  };

  const handleOtp = async () => {
    // add otp api here.
    if (isEmptyOrNullOrUndefined(otp)) {
      setIsError(true);
      showToast(STRING_CONSTANTS.enter_the_otp, "error");
    } else if (otp.length !== 6) {
      setIsError(true);
      showToast(STRING_CONSTANTS.enter_valid_otp, "error");
    }
    // else if (secs < 0) {
    //   setIsError(true);
    //   showToast(STRING_CONSTANTS.Verification_code_expire, "error");
    // }
    else {
      const params = {
        otp: otp,
        number: getValues("mobile_number"),
      };
      if (otp.length < 6) {
        return;
      } else {
        verifyOtpApi();
        setShowPopup(!showPopup);
        handleSubmit(async (data1) => {
          const userData = {
            mobile_number: getValues("mobile_number").replace(/\s/g, ""),
          };
          const apiResponse = await makePatchCall({
            url: `${endpoints.profile}`,
            apiPayload: userData,
          });

          if (apiResponse.status.code === 200) {
            setBookingDetail(apiResponse);
            getProfileDetail(apiResponse);
          }
        })();

        // getProfileDetail(apiResponse);
        //  else {
        //   setIsError(true);
        //   showToast(STRING_CONSTANTS.enter_valid_otp, "error");
        // }
      }
    }
  };

  const handleResetOtp = () => {
    checkUserApi();
    setSecs(60);
    setResendOtp(false);

    AuthInputRef.current?.clear();
  };

  // const getaddress = () => {
  //   const selectedData = mainstylistLocationData?.results?.find((item) => {
  //     return item.id === getValues(apiConstants.location);
  //   });

  //   return selectedData;
  // };

  return (
    <div className="step_5 sign_up_screen otp_screen">
      <div className="upper-wrapper">
        <div className="back-logo"></div>
        <h1>Verify Number</h1>

        <p>
          {` To update your number please enter a one time code we’ve sent to your
          mobile number: ${watch("mobile_number")}
          `}
        </p>
        <p className="verification_number">
          {/* {`Verify your number, we’ve sent a one time sign-up code to your mobile
            number: ${getValues(apiConstants.country_code)} ${getValues(
            apiConstants.mobile_number
          )}`} */}
        </p>

        <div className="otp_number">
          {/* <input type="number" /> */}

          <AuthCode
            onChange={handleChange}
            allowedCharacters="numeric"
            length={6}
            containerClassName={isError ? "input_error otp_input" : "otp_input"}
            ref={AuthInputRef}
          />

          <p className="resend_msg">
            Didn’t receive a code?{" "}
            {resendOtp ? (
              <span style={{ cursor: "pointer" }} onClick={handleResetOtp}>
                Send again{" "}
              </span>
            ) : (
              <b>Resend in {secs} secs </b>
            )}
          </p>
        </div>
      </div>
      <div className="bottom-wrapper">
        <Button
          className="continue-btn"
          type="submit"
          btnTxt={"Confirm OTP"}
          isLoading={false}
          onClick={handleOtp}
        />
      </div>
    </div>
  );
}
