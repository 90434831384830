import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElement } from "@stripe/stripe-js";
import React, { useEffect, useMemo, useState } from "react";
import {
  makeDeleteCall,
  makeGetCall,
  makePostCall,
} from "../../effects/requests";
import { showToast } from "../../utils";
import { images } from "../../utils/constants/assets";
import endpoints from "../../utils/endpoints";
import useStore from "../../zustandstore";
import useResponsiveFontSize from "../SignUp/useResponsiveFontSize";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
const AddShowCard = () => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const { stylistUniqueCode } = useStore((state: any) => state);
  const [showAddCard, setShowAddCard] = useState(false);
  const [savedCardList, setSavedCardList] = useState([]);

  const [showPopup, setShowPopup] = useState(false);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const deleteCardPop = () => {
    setShowPopup(true);
  };
  async function cardsApi() {
    try {
      const apiResponse = await makeGetCall({
        url: endpoints.payment_methods,
        params: { stylist_unique_code: stylistUniqueCode },
      });
      const res = await apiResponse;

      if (res?.data) {
        setSavedCardList(res?.data || []);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    cardsApi();
  }, []);
  //Handle Delete Card
  const handleDeleteCard = async (id) => {
    try {
      await makeDeleteCall({
        url: endpoints.payment_methods,
        apiPayload: { source_id: id },
      });
      cardsApi();
      setShowPopup(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddCard = (e) => {
    e.preventDefault();
    makePostCall({ url: endpoints.payment_methods }).then((res) => {
      stripe
        .confirmCardSetup(res.data, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        })
        .then((res) => {
          showToast(res?.error?.message, "error");

          elements.getElement(CardNumberElement).clear();
          elements.getElement(CardCvcElement).clear();
          elements.getElement(CardExpiryElement).clear();
          cardsApi();
          if (!res.error) setShowAddCard(!showAddCard);
        });
    });
  };

  const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            fontWeight: 700,
            color: "#2F2F2F",
            // letterSpacing: "0.025em",
            fontFamily: "sans-serif",
            "::placeholder": {
              color: "grey",
            },
          },
          invalid: {
            color: "#9e2146",
          },
        },
      }),
      [fontSize]
    );
    return options;
  };
  const options = useOptions();

  //Render Saved Card List
  const renderSavedCards = () => {
    const renderCardList = savedCardList.map((card) => {
      return (
        <div key={card.id} className="custom_checkbox">
          <div>
            <div>**** {card.last4}</div>
          </div>
          <button type="button" className="delete-card" onClick={deleteCardPop}>
            <img src={images.del_card_icon} alt="" />
          </button>
          {showPopup ? (
            <div className="pay-popup-outer">
              <div className="overlay" onClick={handlePopupClose}></div>
              <div className="pay-popup del-popup">
                <div className="gif-img">
                  <img src="/assets/images/gifs/danger-sign.gif" alt="" />
                </div>
                <div className="heading">{STRING_CONSTANTS.are_sure}</div>
                <div className="subtitle">
                  {STRING_CONSTANTS.you_want_delete}
                </div>

                <div>
                  <div className="close-btn-delete">
                    <div
                      className="close-btn close-btn-1"
                      // onClick={handleDeleteConfirm}

                      onClick={() => handleDeleteCard(card.id)}
                    >
                      Yes
                    </div>
                    <div className="close-btn" onClick={handlePopupClose}>
                      No
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    });
    return renderCardList;
  };

  return (
    <div>
      {savedCardList && renderSavedCards()}

      <div className="add-new-card">
        <button onClick={() => setShowAddCard(!showAddCard)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4.33333C0 1.9401 1.9401 0 4.33333 0H19.6667C22.0599 0 24 1.9401 24 4.33333V19.6667C24 22.0599 22.0599 24 19.6667 24H4.33333C1.9401 24 0 22.0599 0 19.6667V4.33333ZM13 6.33333C13 5.78105 12.5523 5.33333 12 5.33333C11.4477 5.33333 11 5.78105 11 6.33333V11H6.33333C5.78105 11 5.33333 11.4477 5.33333 12C5.33333 12.5523 5.78105 13 6.33333 13H11V17.6667C11 18.2189 11.4477 18.6667 12 18.6667C12.5523 18.6667 13 18.2189 13 17.6667V13H17.6667C18.2189 13 18.6667 12.5523 18.6667 12C18.6667 11.4477 18.2189 11 17.6667 11H13V6.33333Z"
              fill="#0D5A61"
            />
          </svg>
        </button>{" "}
        Add new card
      </div>
      {showAddCard && (
        <div className="pay-popup-outer">
          <div
            className="overlay"
            onClick={() => setShowAddCard(!showAddCard)}
          ></div>
          <div className="pay-popup">
            <h1>Add an new Card</h1>
            <p>Please enter the card details below.</p>
            <form onSubmit={(e) => handleAddCard(e)} className="payment-method">
              <label>
                Card number
                <CardNumberElement options={options} />
              </label>
              <label>
                CVC
                <CardCvcElement options={options} />
              </label>
              <label>
                Expiration date
                <CardExpiryElement options={options} />
              </label>
              <div className="buttons">
                <button
                  onClick={() => setShowAddCard(!showAddCard)}
                  className="no continue-btn"
                >
                  No
                </button>
                <button
                  type="submit"
                  onSubmit={(e) => handleAddCard(e)}
                  className="yes continue-btn"
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddShowCard;
